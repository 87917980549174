.login-content{
    background: url(../../assets/images/login-bg.png) center/cover no-repeat;
}
.login-content .row {
    height: calc(100vh - 70px);
    max-width: 1440px;
}

.login-form {
    padding: 30px 20px;
    border: 5px solid #f4f4f4;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(141deg, #2c32b8 -9%, #7033d5 35%, #8133db 53%, #9933e6 70%);
    background-image: linear-gradient(141deg, #2c32b8 -9%, #7033d5 35%, #8133db 53%, #9933e6 70%);
}

.login-form input {
    color: #fff;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    box-shadow: none;
    outline-style: none;
    width: 100%;
    background-color: transparent;
    padding: 18px 0 8px;
    height: auto;
}

.login-form input~label {
    font-size: 14px;
    font-weight: 700;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: top .2s, font .2s;
    -moz-transition: top .2s, font .2s;
    transition: top .2s, font .2s;
}

.login-form input~.underline {
    background-image: -webkit-linear-gradient(to top, #a2000f 50%, #d75348 0%);
    background-image: linear-gradient(to top, #a2000f 50%, #d75348 0%);
    position: absolute;
    bottom: 0;
    right: 50%;
    left: 50%;
    width: 0;
    height: 2px;
    -webkit-transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    -moz-transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    z-index: 3;
}

.login-form input:focus~label {
    color: #747474 !important;
}

.login-form input:focus~label,
.login-form input:valid~label {
    top: 0;
    font-size: 12px;
}

.login-form input:-webkit-autofill~label {
    top: 0;
    font-size: 12px;
}

.login-form input:focus~.underline {
    left: 0;
    right: 0;
    width: 100%;
}

.forget_link{
    color: #05fff3;
}

@media screen and (max-width: 1500px) {
	#login-form .form-title h2 {
		font-size: 30px;
	}
}

@media screen and (max-width: 1199px) {
	#login-content .row {
		height: calc(100vh - 142px);
	}
}

@media screen and (min-width: 992px) and (max-height: 530px) {
    .login-content .row {
        height: auto;
        padding: 30px 0;
    }
}

@media screen and (max-width: 991px) {
    .login-content .row {
        height: calc(100vh - 126px);
    }

    .login-content .row .colmn-1 {
        align-self: flex-end;
        margin-bottom: 40px;
    }

    .login-content .row .colmn-2 {
        align-self: flex-start;
    }
}

@media screen and (max-width: 991px) and (max-height: 845px) {
    .login-content .row {
        height: auto;
        padding: 30px 0;
    }
}

@media screen and (max-width: 640px) {
	#login-content .row {
		height: calc(100vh - 163px);
	}
}

@media screen and (max-width: 575px) {
    #login-content .row {
		height: calc(100vh - 158px);
    }
    
    .login-form .form-title h2 {
        font-size: 22px;
        line-height: 32px;
    }
}

@media screen and (max-width: 440px) {
    .login-content .row {
        height: calc(100vh - 112px);
    }

    .login-form .form-title h2 {
        font-size: 18px;
        line-height: 28px;
    }

    .login-form .btn {
        padding: 12px;
        max-width: 180px;
        font-size: 15px;
    }
}

@media screen and (max-width: 440px) and (max-height: 830px) {
    .login-content .row {
        height: auto;
    }
}