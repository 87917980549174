
.hide{
    display: none;
}
.show{
    display: block;
}
.highlight{
    background: red;
}
.timeline-slide{
    position: absolute;
}
.timeline-audio-track{
    position: absolute;
}

.timeline-component-pin{
    transition: transform 1s;
    transition-timing-function: linear;
}

.timeline-drag {
    width: 18px;
    color: #fff;
    align-self: flex-start;
}   


.new-track {
    background: #333;
    border: dotted white !important;
}
