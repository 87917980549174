#editor-wrapper {
	display: grid;
	grid-template-rows: 72px 1fr 248px;
	grid-template-columns: 100px 1fr;
	height: 100vh;
	overflow: hidden;
}

#app-editor-header {
	grid-column: span 3;
	padding: 17px 0;
	background-color: #000;
	opacity: 0.95;
	-webkit-box-shadow: 0 2px 20px 0 #000000;
	box-shadow: 0 2px 20px 0 #000000;
}

#app-editor-header .btn-wrapper .btn,
#app-editor-header .btn-wrapper span {
	font: 600 14px/normal 'SourceSansPro', sans-serif;
	padding: 8px;
	max-width: 132px;
}

#app-editor-header #nxt:hover {
	background-color: #fff;
	color: #8133db !important;
}

#app-editor-header #nxt:hover span {
	color: #8133db !important;
}

#editor-nav {
	-webkit-box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
	box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
	z-index: 5;
}

#editor-tab-cont .nav-wrapper #inner-tab::-webkit-scrollbar {
	height: 2px;
}

#editor-nav .nav-link {
	padding: 15.85px 0;
	color: #747474;
	font: 600 14px/normal 'ProximaNova', sans-serif;
	border-radius: 0;
}

#editor-nav .nav-link.active,
#editor-nav .nav-link:hover {
	background-image: -webkit-linear-gradient(150deg, #2c32b8 -11%, #7033d5 38%, #8133db 59%, #9933e6 78%);
	background-image: linear-gradient(150deg, #2c32b8 -11%, #7033d5 38%, #8133db 59%, #9933e6 78%);
	color: #fff !important;
}

#editor-nav .nav-link span {
	color: currentColor !important;
	transition: none !important;
}

#editor-tab-wrap #script-tab-cont {
	padding: 40px 0 32px;
	height: calc(100vh - 72px);
	overflow: hidden;
}

#script-tab-cont .inner-row .inner-col-1 {
	padding: 0 32px 0 24px;
	border-right: 1px solid #363839;
}

#editor-tab-wrap #script-form label {
	font: 600 14px/normal 'SourceSansPro', sans-serif;
	color: #747474;
	margin-bottom: 0;
}

#editor-tab-wrap #vid-script {
	height: calc(100vh - 240px);
	overflow: auto;
}

#editor-tab-wrap #vid-script .script-list {
	overflow-y: scroll;
	height: 100%;
}

#editor-tab-wrap #vid-script ul li {
	font: 400 16px/2.3 'SourceSansPro', sans-serif;
	color: #fff;
}

#editor-tab-wrap #vid-script ul li small {
	font-size: 12px;
	color: #d19df8;
	padding-left: 5px;
}

#script-tab-cont .inner-row .inner-col-2 {
	padding: 0 24px 0 32px;
}

#script-tab-cont .inner-row .inner-col-2 .card {
	border: solid 1px #363839;
	background-color: #262626;
	border-radius: 0;
}

#script-tab-cont .plyr::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	z-index: 1;
}

#script-tab-cont .plyr .plyr__controls {
	background: transparent;
}

#script-tab-cont .card .plyr__controls {
	justify-content: flex-start;
}

#script-tab-cont .card .plyr__controls__item:first-child {
	margin-right: 0;
}

#script-tab-cont .card .plyr__volume {
	margin-left: 1px !important;
}

#script-tab-cont .card .plyr__time {
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

#script-tab-cont .card .plyr__time--duration {
	margin-right: auto;
}

#script-tab-cont .card .card-body {
	padding: 32px 16px 16px;
}

#script-tab-cont .card .card-body .date-wrapper {
	border-bottom: 1px solid #363839;
}

#script-tab-cont .card .card-body p,
#script-tab-cont .card .card-body p .date,
#script-tab-cont .card .card-body label {
	color: #747474;
	font: 600 14px/normal 'SourceSansPro', sans-serif;
	margin: 0;
}

#script-tab-cont .card .card-body .vid-link a, 
#script-tab-cont .card .card-body .f-name {
	width: 100%;
	max-width: 450px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.4;
	white-space: nowrap;
}

#script-tab-cont .card .card-body p .date,
#script-tab-cont .card .card-body .f-name {
	font-weight: 400;
	display: block;
	font-size: 16px;
}

#editor-tab-cont.active {
	display: flex;
}

#editor-tab-cont #inner-tab-cont {
	background-color: #262626;
	width: 100%;
	order: 1;
	flex: 0 0 300px;
	max-height: calc(100vh - 168px);
	min-height: calc(100vh - 168px);
	overflow-y: auto;
	padding: 24px 20px 34px;
	box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
	/*z-index: 1;*/
}

#editor-tab-cont .nav-wrapper {
	order: 2;
	flex: 1 1 auto;
}

#editor-tab-cont .nav-wrapper #inner-tab {
	border: 0;
	justify-content: center;
	box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
	background-color: #262626;
}

#editor-tab-cont .nav-wrapper #inner-tab li a {
	padding: 14px 28.73px 13px;
	width: 100%;
	border: 0;
	font-weight: 500;
	font-size: 14px;
	color: #747474;
	border-radius: 0;
	background: transparent;
	text-align: center;
	outline: none;
}

#editor-tab-cont .nav-wrapper #inner-tab li a.active,
#editor-tab-cont .nav-wrapper #inner-tab li a:hover,
#editor-tab-cont .nav-wrapper #inner-tab li a.active span,
#editor-tab-cont .nav-wrapper #inner-tab li a:hover span,
#editor-tab-cont #media-tabs .nav-link.active,
#editor-tab-cont #media-tabs .nav-link:hover {
	color: #ffffff !important;
}

#editor-tab-cont .nav-wrapper #inner-tab li a::after,
#editor-tab-cont #media-tabs .nav-link:after {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: transparent;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

#editor-tab-cont .nav-wrapper #inner-tab li a.active::after,
#editor-tab-cont .nav-wrapper #inner-tab li a:hover::after,
#editor-tab-cont #media-tabs .nav-link.active::after,
#editor-tab-cont #media-tabs .nav-link:hover::after {
	background-color: #8133db;
}

#editor-tab-cont .label {
	display: block;
	font: 600 12px/normal 'Montserrat', sans-serif;
}

#editor-tab-cont .text-customization select,
#editor-tab-cont .text-to-spch select,
#editor-tab-cont input {
	padding: 7px 12px;
	font: 600 14px/normal 'SourceSansPro', sans-serif;
}

#editor-tab-cont .text-customization select,
#editor-tab-cont .text-to-spch select {
	-webkit-appearance: none;
	cursor: pointer;
}

#editor-tab-cont .text-customization select~.icon,
#editor-tab-cont .text-to-spch select~.icon,
#editor-tab-cont .text-customization input~.icon {
	position: absolute;
	top: 50%;
	right: 15px;
	line-height: 0;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}

#editor-tab-cont .text-customization .sel-wrapper {
	flex-basis: 66.925%;
}

#editor-tab-cont .text-customization .input-wrapper {
	flex-basis: 29.975%;
	flex-grow: 1;
}

#editor-tab-cont .text-customization .txt-edit-btn {
	flex: 1 1 36px;
	padding: 0;
	margin: 0 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 36px;
}

#editor-tab-cont .text-alignment .input-group-prepend {
	flex: 0 0 100px;
}

#editor-tab-cont .text-alignment .input-group-prepend .icon {
	position: absolute;
	top: 50%;
	left: 10px;
	z-index: 1;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #747474;
}

#editor-tab-cont .text-customization #t-lineheight {
	padding-left: 32px;
}

#editor-tab-cont .input-color {
	flex: 1 1 100px;
	margin-right: 7px;
	margin-bottom: 8px;
}

#editor-tab-cont #t-bg-btn,
#editor-tab-cont #t-color-btn,
#editor-tab-cont #bg-color-btn,
#editor-tab-cont #bg-grad-btn {
	width: 24px;
	height: 24px;
	position: absolute;
	top: 50%;
	left: 8px;
	border-radius: 3px;
	border: solid 2px rgba(255, 255, 255, 0.5);
	z-index: 1;
	-webkit-transform: translateY(-85%);
	-moz-transform: translateY(-85%);
	transform: translateY(-85%);
	transition: none !important;
}

#editor-tab-cont #tBg-hex,
#editor-tab-cont #tColor-hex,
#editor-tab-cont #bgColor-hex,
#editor-tab-cont #bgGrad-hex {
	padding-left: 40px;
}

#editor-tab-cont .text-color label,
#editor-tab-cont .text-bg label,
#editor-tab-cont .bg-color label,
#editor-tab-cont .bg-grad label,
#editor-tab-cont .text-to-spch label {
	display: block;
	position: relative;
	color: #747474;
	font: 600 10px/normal 'SourceSansPro', sans-serif;
	left: 0;
	top: 0;
	margin: 2px 0 0;
}

#editor-tab-cont .rgb-wrapper {
	flex: 0 0 36px;
	margin-right: 8px;
	margin-bottom: 8px;
}

#editor-tab-cont .rgb-wrapper input {
	padding: 7px 0;
	text-align: center;
}

#editor-tab-cont .no-color,
#editor-tab-cont .swatch {
	width: 28px;
	height: 28px;
	border: 0;
	border-radius: 2px;
	padding: 0;
	margin-right: 1px;
	flex: 0 0 27.5px;
	margin-bottom: 1px;
	overflow: hidden;
	outline: none !important;
}

#editor-tab-cont .no-color::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 40px;
	background: #a2000f;
	transform: rotate(45deg) translate(5px, -13px);
}

#editor-tab-cont .custom-switch .custom-control-label::before {
	top: 0;
	left: 0;
	width: 48px;
	height: 24px;
	border: 1px solid #424344;
	border-radius: 83px;
	background-color: #000;
	-webkit-transform: translateX(-48px);
	-moz-transform: translateX(-48px);
	transform: translateX(-48px);
}

#editor-tab-cont .custom-control-input:focus~.custom-control-label::before {
	box-shadow: none;
}

#editor-tab-cont .custom-switch .custom-control-label::after {
	top: 1.9px;
	left: -46px;
	width: 20px;
	height: 20px;
	background-color: #fff;
	border-radius: 50%;
}

#editor-tab-cont .custom-control-input:checked~.custom-control-label::before {
	border-color: #747474;
	background-color: blueviolet;
	background-image: -webkit-linear-gradient(137deg, #2c32b8 -8%, #7033d5 34%, #8133db 51%, #9933e6 68%);
	background-image: linear-gradient(137deg, #2c32b8 -8%, #7033d5 34%, #8133db 51%, #9933e6 68%);
}

#editor-tab-cont .custom-switch .custom-control-input:checked~.custom-control-label::after {
	-webkit-transform: translateX(25px);
	transform: translateX(25px);
}

#editor-tab-cont .preview-wrapper {
	display: block;
	width: 100%;
	max-width: max-content;
	margin: 24px auto 0;
}

#editor-tab-cont .preview-wrapper .controls {
	background-color: #000;
	padding: 12px 0 12px;
}

#editor-tab-cont .preview-wrapper .controls li {
	border-color: #747474 !important;
}

#editor-tab-cont .controls .transparency {
	font: 600 10px/normal 'SourceSansPro', sans-serif;
}

#editor-tab-cont .controls #transparent {
	height: 3px;
	background-color: #cccccc;
	border-radius: 2px;
	-webkit-appearance: none;
	max-width: 115px;
	cursor: pointer;
	padding: 0;
}

#editor-tab-cont .controls #transparent::-moz-range-progress {
	background-color: #8133db;
}
#editor-tab-cont .controls #transparent::-webkit-range-progress {
	background-color: #8133db;
}

#editor-tab-cont .controls #transparent::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 10px;
	height: 10px;
	background-color: #8133db;
	border: solid 1.5px #ffffff;
	border-radius: 50%;
}

#editor-tab-cont .controls #transparent::-moz-range-thumb {
	-webkit-appearance: none;
	width: 9px;
	height: 9px;
	background-color: #8133db;
	border: solid 1.5px #ffffff;
	border-radius: 50%;
}

#editor-tab-cont .time-wrapper .current-time,
#editor-tab-cont .time-wrapper .current-time small {
	font-weight: 600;
}

#editor-tab-cont .time-wrapper .duration-time,
#editor-tab-cont .time-wrapper .duration-time small {
	font-weight: 500;
}

#editor-tab-cont .vid-controls .controls-wrapper {
	width: 100%;
	max-width: 248px;
	margin: 0 auto;
	justify-content: center;
}

#editor-tab-cont .card {
	background-color: transparent;
	border: 0;
	padding: 0;
	overflow: visible;
}

#editor-tab-cont .card .card-header {
	padding: 0;
	border: 0;
}

#editor-tab-cont .card .card-header button {
	text-align: left;
	padding-left: 26px !important;
	line-height: 1.4;
}

#editor-tab-cont .card .card-header button::after {
	content: "";
	width: 16px;
	height: 16px;
	background-color: #ffffff;
	border: solid 4px #8133db;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}

#editor-tab-cont .card .card-header button.collapsed::after {
	background-color: rgba(255, 255, 255, 0.5);
	border: solid 2px #ffffff;
}

#editor-tab-cont #bg .card .color-range #grad-color {
	width: 100%;
	height: 8px;
	border-radius: 4px;
}

#editor-tab-cont #bg .card .color-range .color-stop {
	width: 8px;
	height: 8px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	border: solid 1.5px #fff;
	border-radius: 50%;
	position: absolute;
	top: 50% !important;
	left: 0;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
	cursor: pointer;
}

#editor-tab-cont #bg .card .color-stop.rght-marker {
	left: 100%;
	transform: translate(-100%, -50%);
}

#editor-tab-cont #bg .card .color-stop.active {
	width: 12px;
	height: 12px;
}

#editor-tab-cont .search-img-media~.input-group-prepend,
#editor-tab-cont .search-vid-media~.input-group-prepend,
#editor-tab-cont .search-audio-media~.input-group-prepend {
	color: #747474;
	right: 12px;
	z-index: 4;
}

#editor-tab-cont .up-img-btn,
#editor-tab-cont .up-vid-btn {
	font: 600 14px/normal 'SourceSansPro', sans-serif;
	padding: 8px 16px 8px 30px;
	max-width: 145px;
	border-radius: 4px;
}

#editor-tab-cont .up-img-btn .ico,
#editor-tab-cont .up-vid-btn .ico {
	left: 13px;
}

#editor-tab-cont .results-wrapper {
	margin-bottom: 16px;
	justify-content: center;
	align-items: flex-start;
}

#editor-tab-cont .results-wrapper a {
	flex-basis: 50%;
	padding: 2px;
}

#editor-tab-cont .results-wrapper a > div {
	overflow: hidden;
	padding-bottom: 56.25%;
}

#editor-tab-cont .results-wrapper a > div video {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	object-fit: contain !important;
}

#editor-tab-cont .results-wrapper a img {
	border: 2px solid transparent;
	width: 100%;
	min-height: 75px;
	max-height: 75px;
	object-fit: cover;
}

#editor-tab-cont .results-wrapper a:hover img {
	border-color: #d75348;
;
}

::-webkit-scrollbar {
	width: 2px;
	height: 8px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #9e9e9e;
	border-radius: 4px;
}

#media-tabs .nav-link {
	color: #747474;
	background: no-repeat;
	border: none;
	padding: 0 21px 11px;
}

#editor-tab-cont #collapseFour .vid-time,
#editor-tab-cont #media-tab-cont #vid-clips .vid-time {
	font: 600 9px/normal 'SourceSansPro', sans-serif;
	color: #000;
	border-radius: 3px;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 4px;
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 9;
}

#editor-tab-cont #audio .aud-wrapper {
	width: 100%;
	max-width: 80px;
	min-height: 80px;
	max-height: 80px;
	background-image: linear-gradient(155deg, #2c32b8 -13%, #7033d5 41%, #8133db 63%, #9933e6 84%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1 75px;
	overflow: hidden;
	flex-flow: wrap row;
	margin-bottom: 8px;
}

#editor-tab-cont #audio .aud-wrapper:last-child {
	margin-right: auto;
	margin-left: 9px;
  }

#editor-tab-cont #audio .aud-wrapper .aud-ctrls > div {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 0;
	opacity: .6;
	transform: translateY(-50%);
  }

  #editor-tab-cont #audio .aud-wrapper .aud-ctrls > div wave {
	width: 100% !important;
	max-width: 100%;
  }

  #editor-tab-cont #audio .aud-ctrl-btn {
	color: #7033d5;
	background-color: #fff;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 4px;
	}
	
	#editor-tab-cont #audio .aud-ctrl-btn .play-aud {
    left: 1px;
    /* top: 0.5px; */
}

  #editor-tab-cont #audio span {
	font: 600 9px/1 'SourceSansPro', Sans-serif;
	color: #fff;
	display: block;
	text-align: center;
  }

  #editor-tab-cont #audio #bg-music-collapse .results-wrapper {
	min-height: 300px;
	justify-content: space-between;
  }

#editor-tab-cont #audio .btn-wrapper button {
	font: 600 14px/14px 'SourceSansPro', sans-serif;
	padding: 10px 8px;
	border-radius: 4px;
	max-width: 128px;
}

#editor-tab-cont #audio .btn-wrapper #upload-audio {
	padding-left: 25px;
}

#editor-tab-cont #audio .btn-wrapper button .ico {
	color: #d19df8;
	left: 12px;
}

#editor-tab-cont #audio .btn-wrapper #record {
	max-width: 72px;
}

#editor-tab-cont #audio .btn-wrapper .close {
	max-width: 100%;
}

#editor-tab-cont #audio #time-display {
	font-size: 96px;
	font-weight: 100;
	font-family: 'Montserrat', sans-serif;
}

#editor-tab-cont #audio #rec-aud .modal-footer .btn {
	padding: 11px;
	font: 700 16px/1.5 'Montserrat', sans-serif;
}

#editor-tab-cont .text-to-spch .sel-wrapper {
	flex: 1 1 auto;
}

#editor-tab-cont .text-to-spch .sel-wrapper:first-child {
	flex: 0 0 61px;
}

#editor-tab-cont .text-to-spch select {
	font-size: 12px;
	padding: 7px 8px;
}

#editor-tab-cont .text-to-spch select~.icon {
	top: 35%;
	right: 8px;
}

#editor-tab-cont #enter-script {
	font: 600 12px/normal 'SourceSansPro';
	color: #ffffff;
	padding: 11px 12px;
	height: 150px;
}

#editor-tab-cont #enter-script::placeholder {
	color: #747474;
}

#editor-tab-cont #txt-spch-collapse .btn-wrapper,
#editor-tab-cont #cur-audio-collapse .btn-wrapper {
	position: relative;
}

#editor-tab-cont #motion .motion-title p {
	font-weight: 600;
	line-height: 1.14;
	color: #747474;
}

#editor-tab-cont #motion ul li a {
	font: 500 12px/2 'Montserrat', sans-serif;
	display: block;
}

#editor-tab-cont #transition .transition-list .trans-item {
	flex: 1 1 50%;
	padding: 8px 4px;
}

#editor-tab-cont #transition .trans-item .trans-title {
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	font: 600 10px/normal 'Montserrat', sans-serif;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

#editor-tab-cont #transition .transition-list .trans-item img {
	min-height: 95px;
	object-fit: cover;
}

#prev-canvas {
	height: calc(100vh - 503px);
}

#timeline-row {
	grid-column: span 3;
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background-color: #171717;
}

#timeline-row .timeline-header .timeline-component-zoom {
	padding: 7px;
	border: solid 0.5px #292b2e;
	background-color: #121212;
	flex: 0 0 100px;
}

#timeline-row .timeline-wrapper input[type="range"] {
	height: 2px;
	background-color: #fff;
	border-radius: 3px;
	-webkit-appearance: none;
	margin: 0 6px;
	max-width: 56px;
	cursor: pointer;
	padding: 0;
}

#timeline-row .timeline-wrapper input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 10px;
	height: 10px;
	background-color: #8133db;
	border: 2px solid #fff;
	border-radius: 50%;
}

#timeline-row .timeline-wrapper input[type="range"]::-moz-range-thumb {
	-webkit-appearance: none;
	width: 6px;
	height: 6px;
	background-color: #8133db;
	border: 2px solid #fff;
	border-radius: 50%;
}

#timeline-row .timeline-component-zoom .input-group-append,
#timeline-row .timeline-component-zoom .input-group-prepend {
	margin: 0;
	color: #9e9e9e;
}

#timeline-row .timeline-header .timeline-component-ruler {
	display: flex;
	border: solid 0.5px #292b2e;
	background-color: #121212;
	padding: 0 0 1px 8px;
	flex: 1 1 auto;
}

#timeline-row .timeline-component-ruler .sec {
	position: absolute;
	left: 0;
	top: 0;
	width: 52px;
}

#timeline-row .timeline-component-ruler .sec .sec-pin {
	width: 2px;
	height: 8px;
	border-radius: 0.8px;
	background-color: #555555;
}

#timeline-row .timeline-component-ruler .sec .sec-time {
	display: block;
	font: 500 8px/normal 'ProximaNova', sans-serif;
	color: #555555;
	margin-top: 4px;
}

#timeline-row .timeline-header .timeline-component-pin {
	width: 4px;
	height: 15px;
	background-color: #8133db;
	display: block;
	position: absolute;
	top: 0;
	left: 8px;
	cursor: pointer;
	z-index: 99;
}

#timeline-row .timeline-header .timeline-component-pin:after {
	content: "";
	display: block;
	width: 1px;
	height: 118px;
	background-color: #8133db;
	position: absolute;
	top: 0;
	left: 2px;
}

#timeline-row .timeline-component-main .timeline-component-track_wrapper {
	overflow-x: scroll;
	overflow-y: auto;
	max-height: 240px;
}

#timeline-row .timeline-body .timeline-component-track {
	display: flex;
	align-items: center;
	border: solid 1px #292b2e;
}

#timeline-row .timeline-component-track .timeline-track {
	display: flex;
	min-height: 70px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex: 0 0 100px;
	text-align: center;
	padding: 8px;
	border-right: solid 0.5px #292b2e;
}

#timeline-row .timeline-component-track .timeline-track .track-title {
	width: 100%;
	font: 500 8px/normal 'ProximaNova', sans-serif;
	color: #747474;
	align-self: flex-end;
	margin-bottom: 4px;
}

#timeline-row .timeline-component-track .timeline-track .track-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	align-self: flex-start;
}

#timeline-row .timeline-component-track .timeline-track .track-btns .ico {
	color: #fff;
	flex-grow: 1;
	cursor: pointer;
}

#timeline-row .timeline-component-track .timeline-track .track-btns .ico-hide {
	display: none;
}

#timeline-row .timeline-component-track .timeline-track .volume {
	max-width: 84px;
	margin: 0 auto 4px;
}

#timeline-row .timeline-component-track_wrapper .timeline-component-add-track {
	display: flex;
	border: solid 0.5px #292b2e;
}

#timeline-row .timeline-component-add-track .add-track {
	display: block;
	background-color: #121212;
	font: 500 8px/normal 'Montserrat', sans-serif;
	color: #d75348;
	text-align: center;
	padding: 3px;
	flex: 0 0 100px;
	border-right: 1px solid #292b2e;
}

#timeline-row .timeline-component-main .timeline-track-layer {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	border-left: solid 0.5px #292b2e;
	height: 70px;
	padding: 4px 0 4px 8px;
}

#timeline-row .timeline-track-layer .timeline-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	height: 100%;
	position: relative;
	overflow: hidden;
}

#timeline-row .timeline-track-layer .selected .slide-tile {
	border: solid 2px #ffcc01;
}

#timeline-row .timeline-track-layer .slide-tile.audio > div {
	overflow: hidden;
	height: 100%;
}

#timeline-row .timeline-track-layer .slide-tile.audio > div wave {
	overflow: hidden !important;
}

.react-resizable-handle-se {
	cursor: e-resize;
	height: 100%;
	z-index: 9;
}

#timeline-row .timeline-slide .slide-tile {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: left;
	background-color: #ffffff;
	overflow: hidden;
	cursor: all-scroll;
}

#timeline-row .timeline-slide .slide-tile .icon-wrapper {
	background-color: #171717;
}

#timeline-row .timeline-slide .slide-tile .slide-title {
	color: #000000;
	font: 600 7px/normal 'Montserrat', sans-serif;
}

#timeline-row .timeline-track-layer .timeline-transition {
	padding: 16px 1px;
	background-color: #ffcc01;
	color: #000;
}

#timeline-row .timeline-track-layer .timeline-audio-track {
	height: 100%;
	/* background-color: #2ab365; */
	display: flex;
	max-height: 55px;
	overflow: hidden;
}

#timeline-row .timeline-track-layer .timeline-audio-track .slide-tile.audio {
	background-color: #2ab365;
}

/*Toaster*/
#vid-script #warning-toast {
	position: absolute;
	left: 50%;
	bottom: 300px;
	width: 100%;
	max-width: 100%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

#vid-script #warning-toast .toast-body {
	width: 100%;
	max-width: 793px;
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(83, 96, 107, 0.5);
	border: solid 1px #a2000f;
	background: #f8ebec none !important;
	padding: 23px 16px;
	opacity: 1;
	margin: 0 auto;
}

#vid-script #warning-toast .toast-body p {
	font-weight: bold;
	line-height: normal;
	color: #a2000f;
}

#vid-script .toast-body .btn-wrapper {
	justify-content: center;
	align-items: center;
}

#vid-script #warning-toast .toast-body .btn-wrapper .btn {
	color: #a2000f;
	padding: 9px;
	font: 600 14px/14px 'SourceSansPro', sans-serif;
	max-width: 132px;
	border-radius: 4px;
	border: solid 2px #a2000f;
	box-shadow: 0 2px 4px 0 rgba(162, 0, 15, 0.5);
}

#vid-script #warning-toast .toast-body .btn-wrapper .btn-3 {
	color: #ffffff;
	background-color: #a2000f;
}

#vid-script #warning-toast .toast-body .btn-wrapper .btn-3:hover {
	color: #a2000f !important;
	background-color: #ffffff;
}

#vid-script #warning-toast .toast-body .btn-wrapper .btn-2:hover {
	background-color: #a2000f;
}

/* Screen-size 1440 */
@media screen and (max-width: 1440px) {
	#script-tab-cont .inner-row .inner-col-1 {
		flex-basis: 67%;
		max-width: 897px;
	}

	#script-tab-cont .inner-row .inner-col-2 {
		flex-basis: 33%;
		max-width: 442px;
	}
}

.react-contextmenu {
	background-color: #fff;
	padding: 8px 12px;
	border-radius: 4px;
}
.react-contextmenu .react-contextmenu-item {
	font: 500 15px/1.2 'Montserrat', sans-serif;
	color: #9b33e9;
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
	#editor-tab-cont #inner-tab-cont {
		max-height: calc(100vh - 165px);
		min-height: calc(100vh - 165px);
	}

	#editor-tab-cont #media-tab-cont .results-wrapper {
		min-height: 315px;
	}

	#timeline-row .timeline-component-main .timeline-component-track_wrapper {
		max-height: 120px;
	}

	#timeline-row .timeline-header .timeline-component-pin:after {
		height: 110px;
	}

	#editor-tab-cont .time-wrapper span {
		font-size: 14px;
	}

	#editor-tab-cont .vid-controls .controls-wrapper {
		max-width: max-content;
	}

	#editor-tab-cont .vid-controls svg {
		width: 16px;
	}
}

/* Screen-size 1199 */
@media screen and (max-width: 1199px) {
	#script-tab-cont .inner-row .inner-col-1 {
		flex-basis: 62%;
	}

	#script-tab-cont .inner-row .inner-col-2 {
		flex-basis: 38%;
	}

	#editor-tab-cont .nav-wrapper #inner-tab {
		flex-wrap: nowrap;
		white-space: nowrap;
		overflow: auto;
		justify-content: flex-start;
	}

	#editor-tab-cont .nav-wrapper #inner-tab li {
		flex-grow: 1;
	}

	#editor-tab-cont .nav-wrapper #inner-tab li a {
		padding: 15px 18px;
	}
}

/* Screen-size 991 */
@media screen and (max-width: 991px) {
	#editor-wrapper {
		height: auto;
		grid-template-rows: 72px 1fr auto;
	}

	#script-tab-cont .inner-row {
		flex-wrap: wrap;
	}

	#editor-tab-wrap #script-tab-cont,
	#editor-tab-wrap #vid-script {
		height: auto;
	}

	#script-tab-cont .inner-row .inner-col-1,
	#script-tab-cont .inner-row .inner-col-2 {
		flex-basis: 90%;
		max-width: 100%;
		padding: 0 20px;
		margin: 0 auto;
	}

	#script-tab-cont .inner-row .inner-col-1 {
		border-right: 0;
	}


	#editor-tab-wrap #vid-script {
    min-height: calc(100vh - 459px);
		max-height: calc(100vh - 459px);
		overflow: auto;
}

	#vid-script .toast-bottom-center {
		bottom: 20px;
	}

	#editor-tab-cont {
		flex-wrap: wrap;
	}

	#editor-tab-cont .nav-wrapper {
		order: 1;
		margin-bottom: 50px;
	}

	#editor-tab-cont #inner-tab-cont {
		order: 2;
		max-height: unset;
		flex: 0 0 100%;
		padding: 24px 160px 34px;
		margin-bottom: 180px;
	}

	#editor-tab-cont .input-color {
		flex: 1 1 25%;
	}

	#editor-tab-cont .rgb-wrapper {
		flex: 1 1 2%;
	}

	#editor-tab-cont .no-color,
	#editor-tab-cont .swatch {
		flex: 1 1 10%;
	}

	#editor-tab-cont .time-wrapper .current-time,
	#editor-tab-cont .time-wrapper .duration-time {
		font-size: 12px;
	}

	#editor-tab-cont .vid-controls li a svg,
	#editor-tab-cont .vid-controls #fullscreen svg {
		width: 15px;
	}

	#txt-spch-collapse .text-to-spch {
		flex-wrap: nowrap !important;
	}

	#editor-tab-cont #audio #bg-music-collapse .results-wrapper {
		min-height: unset;
	}

	#timeline-row .timeline-component-main .timeline-component-track_wrapper {
		max-height: 180px;
	}

	#editor-tab-cont .results-wrapper a > div img {
    min-height: 1px;
    max-height: 100%;
    height: 100%;
	}

	#editor-tab-cont .results-wrapper a img {
			min-height: auto;
			max-height: 150px;
	}
}

/* Screen-size 991 */
@media screen and (max-width: 767px) {
	#editor-wrapper {
		grid-template-columns: auto 1fr;
		grid-auto-flow: column;
	}

	#editor-nav {
		flex-direction: row !important;
	}

	#editor-nav .nav-link {
		flex: 1 1 auto;
		margin: 0 !important;
	}

	#editor-tab-cont #inner-tab-cont {
		padding: 24px 100px 34px;
	}
}

@media screen and (max-width: 575px) {
	#editor-wrapper {
		display: block;
		grid-template-rows: auto 1fr auto;
	}

	#editor-tab-cont #inner-tab-cont {
		padding: 24px 60px 34px;
	}

	#script-tab-cont .inner-row .col {
		flex-basis: 100%;
		padding: 0 15px;
	}

	#editor-tab-wrap #script-tab-cont {
		padding: 30px 0;
	}

	#script-tab-cont .card .card-body p .date,
	#script-tab-cont .card .card-body .f-name {
		font-size: 14px;
	}
}

@media screen and (max-width: 440px) {
	#editor-tab-cont #inner-tab-cont {
		padding: 24px;
	}
}

/*
 Custom Code by Adi
 */

.editor_hr{
	color:#424344;
}

#editor-tab-cont .txt-edit-btn.active {
	-webkit-box-shadow: 0 0 20px 0 #000000;
	box-shadow: 0 0 20px 0 #000000;
	border-color: #7621cc;
}
.cursor-pointer{
	cursor: pointer;
}