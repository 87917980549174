/* Support Styles */
#sup-banner {
    padding: 125px 0 0;
    -webkit-box-shadow: 0 2px 30px 0 #000000;
    box-shadow: 0 2px 30px 0 #000000;
    background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 17%, #e8c9ff), linear-gradient(to bottom, #ffffff, #ffffff);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 17%, #e8c9ff), linear-gradient(to bottom, #ffffff, #ffffff);
    z-index: 1;
}

#sup-banner .title-area h3 {
    font-weight: 500;
    line-height: normal;
    color: #9b33e9;
    text-shadow: none;
}

#sup-banner .input-group {
    max-width: 570px;
}

#sup-banner #search-tpc {
    font: 500 18px/normal 'Montserrat', sans-serif;
    color: #000000;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #e8c9ff;
    border: solid 1px #d19df8;
    padding: 15px 24px;
    height: auto;
    caret-color: #9b33e9;
}

#sup-banner #search-tpc:hover,
#sup-banner #search-tpc:focus {
    border: solid 1px #9b33e9;
    box-shadow: 0 2px 2px 0 rgba(155, 51, 233, 0.5);
}

#sup-banner #search-tpc::placeholder {
    color: #d19df8;
}

#sup-banner #search-tpc~.input-group-append {
    position: absolute;
    top: 50%;
    right: 24px;
    color: #9b33e9;
    z-index: 3;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.section {
    padding: 72px 0;
    background-color: #292b2e;
}

.section .title-area h3 {
    text-shadow: 0 2px 5px #000000;
    font-weight: 600;
}

#tpc-section .card-row {
    max-width: 944px;
    margin-bottom: 40px;
}

#tpc-section .card {
    padding: 45px 22px;
    flex-basis: 25%;
    max-width: 218px;
    max-height: 218px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

#tpc-section .card .card-title {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    line-height: normal;
}

#tpc-section .view-tpcs:hover {
    color: #8133db;
    background-color: #fff;
}

#vid-tuts .row {
    margin-left: -7px;
    margin-right: -7px;
}

#vid-tuts .img-wrapper {
    border: 2px solid #752ec5;
    border-radius: 4px;
    overflow: hidden;
}

#vid-tuts .img-wrapper > div {
    padding-bottom: 56.25%;
}

#vid-tuts .img-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

#cs-cta {
    padding: 130px 0;
    background: url('../../assets/images/bg-ad.png') center/cover no-repeat;
}

#cs-cta .cs-btn {
    max-width: 225px;
}

#cs-cta .cs-btn:hover {
    background-color: #ffffff;
    color: #8133db !important;
    box-shadow: 0 8px 16px 0 rgba(116, 20, 187, 0.6);
}

/* All Topic Styles */
.tpc-section {
    padding: 176px 0 72px;
}

.tpc-sm-cap {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    letter-spacing: 1.09px;
    color: #9b33e9;
    text-transform: uppercase;
}

.tpc-section .breadcrumb-item+.breadcrumb-item::before {
    padding-right: 4px;
    color: #9b33e9;
    font-size: 12px;
}

.tpc-lg-cap {
    font-size: 48px;
    line-height: normal;
}

#lft-side-bar {
    max-width: 358px;
    border-radius: 8px;
    box-shadow: 0 0 16px 0 #000000;
    border: solid 1px #363839;
    padding: 32px;
}

#lft-side-bar .title {
    font-size: 16px;
    line-height: normal;
    color: #747474;
}

#lft-side-bar ul li .tpc-item,
#lft-side-bar ul li .tpc-item-no {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #9b9b9b;
}

.tpc-section .tpc-wrapper {
    max-width: 748px;
    margin-left: auto;
    padding-bottom: 32px;
    border-bottom: 1px solid #555;
    margin-bottom: 32px;
    overflow: hidden;
    cursor: pointer;
}

.tpc-title {
    font-weight: 600;
    margin-bottom: 14px;
}

.tpc-meta p,
.tpc-meta span {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: 1.1px;
    color: #666666;
}

#art-tpc .tpc-wrapper,
#vid-tpc .tpc-wrapper {
    cursor: initial;
    border: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.art-wrapper {
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
}

.art-wrapper p {
    font-family: 'SourceSansPro', sans-serif;
    line-height: 1.88;
}

#art-tpc .tpc-meta p,
#art-tpc .tpc-meta span,
#vid-tpc .tpc-meta p,
#vid-tpc .tpc-meta span {
    font-size: 12px;
    letter-spacing: 1px;
}

.vid-tpc .plyr__poster {
    z-index: 0;
}

.vid-tpc .plyr__controls {
    padding: 0;
}

.vid-tpc .plyr__controls #plyr-seek-6201 {
    height: 8px;
    border-radius: 2px;
}

.vid-tpc .plyr--full-ui input[type="range"] {
    height: 8px;
}

.vid-tpc input[type="range"]::-moz-range-progress {
    background-image: -webkit-linear-gradient(to left, #0764e3, #0496ff);
    background-image: linear-gradient(to left, #0764e3, #0496ff);
    height: 8px;
}

.vid-tpc .plyr--video .plyr__progress__buffer,
.vid-tpc .plyr--full-ui.plyr--video input[type="range"]::-moz-range-track,
.vid-tpc .plyr--full-ui input[type="range"]::-moz-range-thumb {
    background-color: transparent;
    border-radius: 2px;
    height: 8px;
    color: transparent;
}

.plyr--hide-controls~.tpc-details {
    opacity: 0;
}

.tpc-section .vid-tpc .tpc-details {
    position: absolute;
    top: 80%;
    left: 24px;
    -webkit-transform: translateY(-40%);
    -moz-transform: translateY(-40%);
    transform: translateY(-40%);
    z-index: 1;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

#vid-tpc .plyr::before,
#vid-tpc .plyr::after,
.tpc-section .vid-tpc .plyr::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    z-index: 0;
}

#vid-tpc .article-wrapper {
    padding: 40px 0 72px;
}

#vid-tpc .plyr {
    max-height: 700px;
}

#vid-tpc .plyr::before {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    max-height: 700px;
    z-index: 2;
}

#vid-tpc .plyr::after {
    top: 100%;
    height: 120px;
    background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), #171717 86%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #171717 86%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: 1;
}

#vid-tpc .plyr__control--overlaid {
    display: none;
}

#vid-tpc .plyr__controls {
    width: 100%;
    max-width: 1064px;
    margin: 0 auto;
    background: transparent;
    bottom: 56px;
}

.img-icon {
    width: 50px !important;
    margin: 0 auto;
}

/* End All Topic Styles */

/* End Support Styles */

/*>>> Responsive <<<*/

/* Screen-size 991 */
@media screen and (max-width: 991px) {
    #vid-tuts {
        padding-bottom: 24px;
    }

    #tpc-section .card-row {
        justify-content: center;
        margin-bottom: 16px;
    }

    #tpc-section .card {
        margin: 0 12px 24px !important;
        flex-basis: 50%;
        padding: 45px 0;
    }

    .tpc-lg-cap {
        font-size: 35px;
    }

    .tpc-section .vid-tpc .tpc-details {
        top: 70%;
    }

    #cs-cta {
        padding: 80px 0;
    }

}

@media screen and (max-width: 1023px) {
    #tpc-section .card{ margin-left: 0 !important;}
}

/* Screen-size 767 */
@media screen and (max-width: 767px) {
    .tpc-lg-cap {
        font-size: 32px;
    }

    #lft-side-bar {
        max-width: 100%;
        margin-bottom: 50px;
    }

    #vid-tpc {
        padding-top: 60px !important;
    }

    #vid-tpc .article-wrapper {
        padding: 20px 0 72px;
    }
}


/* Screen-size 440 */
@media screen and (max-width: 440px) {
    .section {
        padding: 50px 0;
    }

    .tpc-lg-cap {
        font-size: 28px;
    }

    .tpc-section .vid-tpc .tpc-details {
        top: 58%;
    }

    #sup-banner #search-tpc {
        font-size: 16px;
        padding: 8px 15px;
    }

    #sup-banner #search-tpc~.input-group-append {
        right: 15px;
    }

    #sup-banner #search-tpc~.input-group-append svg {
        width: 18px;
    }

    #tpc-section .card {
        flex-basis: 100%;
        margin: 0 auto 24px !important;
    }

    #vid-tpc {
        padding-top: 104px !important;
    }

    #art-tpc .tpc-meta,
    #vid-tpc .tpc-meta {
        flex-wrap: wrap;
    }

    #art-tpc .tpc-meta h6,
    #vid-tpc .tpc-meta h6 {
        flex-basis: 100%;
        text-align: left !important;
        margin-top: 10px;
    }

    #vid-tuts {
        padding-bottom: 0;
    }

    #cs-cta {
        padding: 60px 0;
    }
}


@media screen and (max-width: 767px) {
    #tpc-section .card{ max-width: 100% !important; width: 100% !important;}
	#tpc-section .card{ max-width: 100% !important; width: 100% !important;}
}