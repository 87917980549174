/*>>> Responsive <<<*/

/* Screen-size 1440 */
@media screen and (max-width: 1600.95px) {
    .Upgrade-CourseReelAl .modal-content {
        top: 163px;
      
      }
}

/* Screen-size 1366 */
@media screen and (max-width: 1366.95px) {
    .Upgrade-CourseReelAl .modal-content {
      top: 130px;
    
    }
}

/* Screen-size 1199 */
@media screen and (max-width: 1199px) {
    .big-heading {
        font-size: 6.1vw;
    }

    #intro-section {
        padding: 200px 0 100px;
    }

    #project-section .card-wrapper {
        max-width: 100%;
    }
}

/* Screen-size 991 */
@media screen and (max-width: 991px) {
    .big-heading {
        font-size: 6.9vw;
    }

    #intro-section {
        padding: 130px 0 80px;
    }

    #proj-title-bar .title-area,
    #proj-title-bar .colmn-2 .inner-row {
        justify-content: center;
    }

    #int-sec-1 .add-acc-wrapper img {
        width: 100px;
    }

    #int-sec-2 .acc-details-wrapper .img-wrapper {
        margin: 0 auto 20px;
        padding-right: 0 !important;
    }

    #int-sec-2 .acc-details-wrapper .inner-row {
        flex-wrap: wrap;
    }

    #int-sec-2 .not-connected .inner-row-wrapper {
        flex: 1 1 100%;
    }
}

/* Screen-size 767 */
@media screen and (max-width: 767px) {
    #intro-section {
        padding: 120px 0 60px;
    }
}

/* Screen-size 640 */
@media screen and (max-width: 640px) {
    #intro-section {
        padding: 100px 0 50px;
    }
}

/* Screen-size 575 */
@media screen and (max-width: 575px) {
    .big-heading {
        font-size: 10vw;
    }
}

/* Screen-size 440 */
@media screen and (max-width: 440px) {
    #single-project .card {
        min-height: auto;
    }
}