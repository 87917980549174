/* Project Styles */
#proj-title-bar {
		background-color: #262626;
		margin-top: 104px;
		padding: 17px 0;
		box-shadow: inset 0 1px 0 0 #363839;
}

#proj-title-bar .title-area h1,
#proj-title-bar .title-area a span {
		font-family: 'SourceSansPro', sans-serif;
		line-height: normal;
		font-weight: 600;
		margin-right: 14px;
}

#proj-title-bar .title-area .no-of-proj {
		margin-left: 14px;
		font-family: 'SourceSansPro', sans-serif;
		color: #9e9e9e;
}

#proj-title-bar #search {
		border-radius: 4px;
		border: solid 1px #555555;
		background-color: #424344;
		padding: 7.5px 13px;
		color: #ffffff;
		font: 600 14px/1.36 'SourceSansPro', sans-serif;
		height: auto;
		width: 100%;
		max-width: 270px;
}

#proj-title-bar #search::placeholder {
		color: #747474;
}

#proj-title-bar #search~.input-group-prepend {
		position: absolute;
		top: 50%;
		right: 12px;
		color: #747474;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		transform: translateY(-50%);
		z-index: 3;
}

#proj-title-bar #search:focus~.input-group-prepend {
		color: #9e9e9e;
}

#proj-title-bar #search:hover,
#proj-title-bar #search:focus,
#single-project #edit-proj .bootstrap-tagsinput:hover,
#single-project #edit-proj .bootstrap-tagsinput.focus,
#single-project .card .card-body #privacy:focus {
		-webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
		border-color: #747474;
}

#proj-title-bar #add-proj,
#proj-title-bar #save-proj {
		font: 600 14px/14px 'SourceSansPro', sans-serif;
		padding: 9px;
		max-width: 132px;
}

#proj-title-bar #add-proj:hover,
#proj-title-bar #save-proj:hover {
		color: #8133db !important;
		background-color: #fff;
}

#project-section {
		padding: 40px 0 32px;
}

#project-section .col .details-wrapper.row {
		margin-bottom: 40px;
}

#project-section .col .details-wrapper .meta-wrapper {
		cursor: pointer;
}

#project-section .meta-wrapper:hover span,
#project-section .meta-wrapper:hover p,
#project-section .meta-wrapper:hover~.time span {
		color: #d75348 !important;
}

#project-section .status.rendering .progress-wrapper {
		display: flex !important;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		padding: 40px;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 8;
}

#project-section .progress-wrapper .progress-label {
		font: 500 16px/1.2 "Montserrat", sans-serif;
}
#project-section .progress-wrapper .progress {
		height: 10px;
}

#project-section .progress-wrapper .progress-bar {
		background-image: -webkit-linear-gradient(94deg, #2c32b8 -4%, #7033d5 26%, #8133db 38%, #9933e6 50%);
		background-image: linear-gradient(94deg, #2c32b8 -4%, #7033d5 26%, #8133db 38%, #9933e6 50%);
		transition: none;
}

/* End Project Styles */

/* Single-Project Styles */
#single-project {
		border-top: 1px solid #363839;
}

#single-project .colmn-1 {
		padding: 40px 40px 122px 15px;
		border-right: 1px solid #363839;
}

#single-project .title-area h3 {
		font-family: 'SourceSansPro', sans-serif;
		font-weight: 600;
		line-height: normal;
}

#single-project #edit-proj .bootstrap-tagsinput {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		width: 100%;
		min-height: 170px;
}

#single-project #edit-proj textarea {
		height: 242px;
}

#edit-proj label,
#edit-proj label span,
#single-project .card .card-body label {
		font: 600 14px/normal 'SourceSansPro', sans-serif;
		color: #747474;
		margin-bottom: 0;
}

#single-project #edit-proj .bootstrap-tagsinput .tag {
		color: #8133db;
		margin-right: 8px;
		cursor: pointer;
}

#single-project #edit-proj .bootstrap-tagsinput .tag span {
		display: none;
}

#single-project #edit-proj .bootstrap-tagsinput input {
		color: #fff;
		background: transparent;
		border: 0;
		padding: 0;
		box-shadow: none;
}

#single-project .colmn-2 {
		padding: 40px 15px 0 40px;
}

#single-project .card {
		border: solid 1px #363839;
		background-color: #262626;
		height: 100%;
		min-height: 520px;
		max-height: 630px;
}

#single-project .card .plyr__controls {
		justify-content: flex-start;
}

#single-project .card .plyr__controls__item:first-child {
		margin-right: 0;
}

#single-project .card .plyr__time--duration {
		margin-right: auto;
}

#single-project .card .plyr__volume {
		margin-left: 1px !important;
}

#single-project .card .plyr__time {
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

#single-project .card .plyr__time+.plyr__time::before {
		content: '/';
}

#single-project .card .card-body {
		padding: 32px 16px 16px;
}

#single-project .card .card-body .details-wrapper,
#single-project .card .card-body .date-wrapper {
		border-bottom: 1px solid #363839;
}

#single-project .card .card-body p,
#single-project .card .card-body p .date {
		color: #747474;
		font: 600 14px/normal 'SourceSansPro', sans-serif;
		margin: 0;
}

#single-project .card .card-body p .date {
		font-weight: 400;
		display: block;
		font-size: 16px;
}

#single-project .card .card-body label {
		margin-bottom: 2px;
}

#single-project .card .card-body .details-wrapper .link,
#single-project .card .card-body .details-wrapper .vid-title .f-name {
		font-family: 'SourceSansPro', sans-serif;
}

#single-project .card .card-body .details-wrapper .vid-title .f-name {
		text-overflow: ellipsis;
		overflow: hidden;
		display: block;
		white-space: nowrap;
}

/* End Single-Project Styles */

/* << RESPONSIVE >> */

/* Screen-size 991 */
@media screen and (max-width: 991px) {
	#proj-title-bar {
		margin-top: 100px;
	}
	#project-section .col:last-child .details-wrapper.row {
			margin-bottom: 0;
	}

	#single-project {
			padding-bottom: 60px;
	}

	#single-project .col {
		padding: 40px 15px 0;
		border: 0;
		margin: 0 auto;
	}

	#single-project .card {
		max-height: 100%;
	}
}

/*
 Adi Css
 */

.margin-center{
	margin: 0 auto;
}