#notfound {
    position: relative;
    height: 100vh;
    background: url(../../assets/images/bg.jpg) center/cover no-repeat;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#notfound .notfound-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

#notfound .notfound-bg>div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #353434;
}

#notfound .notfound-bg>div:nth-child(1) {
    left: 20%;
}

#notfound .notfound-bg>div:nth-child(2) {
    left: 40%
}

#notfound .notfound-bg>div:nth-child(3) {
    left: 60%
}

#notfound .notfound-bg>div:nth-child(4) {
    left: 80%
}

#notfound .notfound-bg>div:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -0.5px;
    -webkit-transform: translateY(-160px);
    -ms-transform: translateY(-160px);
    transform: translateY(-160px);
    height: 160px;
    width: 2px;
    background-color: #8233dc;
}

.grad-txt {
    background: rgb(61, 50, 191);
    background: -moz-linear-gradient(left, rgba(61, 50, 191, 1) 0%, rgba(142, 51, 225, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(61, 50, 191, 1) 0%, rgba(142, 51, 225, 1) 100%);
    background: linear-gradient(to right, rgba(61, 50, 191, 1) 0%, rgba(142, 51, 225, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3d32bf', endColorstr='#8e33e1', GradientType=1);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

@-webkit-keyframes drop {
    90% {
        height: 20px;
    }

    100% {
        height: 160px;
        -webkit-transform: translateY(calc(100vh + 160px));
        transform: translateY(calc(100vh + 160px));
    }
}

@keyframes drop {
    90% {
        height: 20px;
    }

    100% {
        height: 160px;
        -webkit-transform: translateY(calc(100vh + 160px));
        transform: translateY(calc(100vh + 160px));
    }
}

#notfound .notfound-bg>div:nth-child(1):after {
    -webkit-animation: drop 3s infinite linear;
    animation: drop 3s infinite linear;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

#notfound .notfound-bg>div:nth-child(2):after {
    -webkit-animation: drop 2s infinite linear;
    animation: drop 2s infinite linear;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

#notfound .notfound-bg>div:nth-child(3):after {
    -webkit-animation: drop 3s infinite linear;
    animation: drop 3s infinite linear;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

#notfound .notfound-bg>div:nth-child(4):after {
    -webkit-animation: drop 2s infinite linear;
    animation: drop 2s infinite linear;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.notfound {
    max-width: 520px;
    width: 100%;
    text-align: center;
}

.notfound .notfound-404 {
    height: 300px;
    line-height: 300px;
    position: relative;
}

.notfound .notfound-404 h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 300px;
    font-weight: 700;
    margin: 0px;
}

.notfound-404 p {
    position: absolute;
    top: 6px;
    right: 16px;
    line-height: 1;
    margin: 0 !important;
    font-size: 300px;
    font-family: 'Oswald', sans-serif !important;
    z-index: -1;
    color: #fff;
}

.notfound h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 42px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: #fff;
    text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.8);
}

.notfound p {
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 25px;
}

.notfound a {
    font-family: 'Lato', sans-serif;
    padding: 10px 30px;
    display: inline-block;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 0px 0px 2px #3f4042, 2px 2px 0px 2px #6f07b6;
    box-shadow: 0px 0px 0px 2px #3f4042, 2px 2px 0px 2px #6f07b6;
    text-decoration: none;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    background-color: #6f07b6;
    -webkit-box-shadow: 0px 0px 0px 0px #3f4042, 0px 0px 0px 2px #6f07b6;
    box-shadow: 0px 0px 0px 0px #3f4042, 0px 0px 0px 2px #6f07b6;
}

.notfound-social {
    margin-top: 35px;
}

.notfound-social>a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0px;
    margin: 0px 5px;
}

.notfound-social>a:hover {
    background-color: #00ffc0;
    -webkit-box-shadow: 0px 0px 0px 0px #000, 0px 0px 0px 2px #00ffc0;
    box-shadow: 0px 0px 0px 0px #000, 0px 0px 0px 2px #00ffc0;
}

@media only screen and (max-width: 767px) {
    .notfound .notfound-404 h1 {
        font-size: 240px;
    }

    .notfound-404 p {
        top: 36px;
        right: 62px;
        font-size: 240px;
    }
}

@media only screen and (max-width: 480px) {
    #notfound .notfound {
        padding: 0 15px;
    }

    .notfound .notfound-404 {
        height: 160px;
        line-height: 160px;
    }

    .notfound .notfound-404 h1 {
        font-size: 160px;
    }

    .notfound-404 p {
        display: none;
    }

    .notfound h2 {
        font-size: 26px;
    }
}