/*Integrations Section 1*/
#int-sec-1 {
    padding: 176px 0 72px;
    background-image: -webkit-linear-gradient(to bottom, rgba(23, 23, 23, 0) 36%, #171717), linear-gradient(to bottom, #292b2e, #292b2e);
    background-image: -moz-linear-gradient(to bottom, rgba(23, 23, 23, 0) 36%, #171717), linear-gradient(to bottom, #292b2e, #292b2e);
    background-image: linear-gradient(to bottom, rgba(23, 23, 23, 0) 36%, #171717), linear-gradient(to bottom, #292b2e, #292b2e);
}

#int-sec-1 .title-area h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -1px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    top: 1.1px;
}

#int-sec-1 .acc-wrapper {
    width: 100%;
    max-width: 120px;
    padding: 18.49px 10px;
    margin: 0 12px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
}

#int-sec-1 .acc-wrapper .img-wrapper {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#int-sec-1 .acc-wrapper .label {
    font-weight: 600;
    color: #000000 !important;
}

/* #int-sec-1 .acc-wrapper .img-wrapper img:last-child,
#int-sec-1 .not-connected .img-wrapper img:first-child,
#int-sec-2 .acc-details-wrapper .img-wrapper img:last-child,
#int-sec-2 .not-connected .img-wrapper img:first-child {
    display: none;
} */

#int-sec-1 .not-connected .img-wrapper img:last-child,
#int-sec-2 .not-connected .img-wrapper img:last-child {
    display: block;
}

/*End Integrations Section 1*/

/*Integrations Section 2*/
#int-sec-2 {
    padding: 40px 0 80px;
}

#int-sec-2 .acc-details-wrapper {
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 #000000;
}

#int-sec-2 .not-connected {
    align-items: center;
}

#int-sec-2 .acc-details-wrapper .img-wrapper {
    width: 100%;
    max-width: 64px;
}

#int-sec-2 .acc-details-wrapper .inner-row {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

#int-sec-2 .acc-details-wrapper.not-connected .inner-row {
    padding: 0;
    margin: 0;
    border: 0;
}

#int-sec-2 .not-connected .acc-details h6 {
    border-bottom: solid 1px #ccc;
}

#int-sec-2 .acc-details h6 span {
    font-weight: 600;
    color: #000;
}

#int-sec-2 .acc-details-wrapper .btns-wrapper .btn {
    min-width: 140px;
}

#int-sec-2 .acc-details-wrapper .conn-btn-wrapper .btn {
    max-width: 220px;
    padding: 14px 0;
}

/*End Integrations Section 2*/

/*>>> Responsive <<<*/

/* Screen-size 991 */
@media screen and (max-width: 991px) {
    #int-sec-1 {
        padding: 150px 0 40px;
    }

    #int-sec-1 .add-acc-wrapper img {
        width: 100px;
    }

    #int-sec-1 .acc-list {
        max-width: 470px !important;
    }

    #int-sec-1 .acc-wrapper {
        margin: 0 auto 25px !important;
        flex-basis: 33.33%;
    }

    #int-sec-2 .acc-details-wrapper .btns-wrapper {
        flex-basis: 100%;
        margin-top: 25px;
        justify-content: flex-end;
    }

    #int-sec-2 .acc-details-wrapper .img-wrapper {
        margin: 0 auto 20px;
        padding-right: 0 !important;
    }

    #int-sec-2 .acc-details-wrapper .inner-row {
        flex-wrap: wrap;
    }

    #int-sec-2 .not-connected .inner-row-wrapper {
        flex: 1 1 100%;
    }
}

/* Screen-size 767 */
@media screen and (max-width: 767px) {
    #int-sec-1 {
        padding: 140px 0 20px;
    }

    #int-sec-1 .title-area img {
        max-width: 260px;
    }

    #int-sec-1 .title-area h1 {
        letter-spacing: 0;
        padding: 15px 0 0 !important;
    }

    #int-sec-2 {
        padding: 40px 0 60px;
    }

    #int-sec-2 .acc-details-wrapper .btns-wrapper {
        flex-basis: 100%;
        margin-top: 25px;
        justify-content: center;
        flex-wrap: wrap;
    }

    #int-sec-2 .acc-details-wrapper .img-wrapper {
        margin: 0 auto 20px;
        flex-basis: 100%;
    }

    #int-sec-2 .acc-details-wrapper .acc-details {
        flex-basis: 100%;
    }
}

/* Screen-size 440 */
@media screen and (max-width: 440px) {
    .sm-txt {
        font-size: 12px;
        line-height: 1.5;
    }

    #int-sec-1 .title-area img {
        max-width: 220px;
    }

    #int-sec-1 .title-area h1 {
        font-size: 30px;
    }

    #int-sec-1 .acc-list {
        max-width: 280px !important;
    }

    #int-sec-1 .acc-wrapper {
        flex-basis: 50%;
    }

    #int-sec-2 .acc-details-wrapper {
        padding: 25px 15px !important;
    }

    #int-sec-2 .acc-details-wrapper .btns-wrapper .btn {
        margin: 0 auto 10px !important;
    }
}

.d-none{
    display: none;
}