@import "./assets/css/bootstrap.min.css";
@import "./assets/fonts/font-awesome/css/font-awesome.min.css";
@import "./assets/fonts/fonts.css";
@import "./assets/css/toastr.min.css";

html {
	overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0 !important;
	background-color: #ffffff;
	overflow-x: hidden;
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: #555555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Montserrat', Sans-serif;
	font-weight: 700;
}

p,
a,
li,
span {
	font: 400 16px/1.19 'Montserrat', Sans-serif;
	color: #9e9e9e;
}

p:last-child {
	margin-bottom: 0 !important;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 40px;
	line-height: 50px;
}

h2 {
	font-size: 36px;
	line-height: 46px;
}

h3 {
	font-size: 32px;
	line-height: 1.22;
}

h4 {
	font-size: 24px;
	line-height: 32px;
}

h5 {
	font-size: 22px;
	line-height: 32px;
}

h6,
.sub-txt {
	font-size: 20px;
	line-height: 1.6;
}

a,
p,
input[type="submit"],
button,
span,
img {
	text-decoration: none !important;
	-webkit-transition: all 0.5s ease !important;
	-moz-transition: all 0.5s ease !important;
	transition: all 0.5s ease !important;
}

a:hover,
a:hover p,
a:hover span,
a:hover i {
	color: #d75348 !important;
}

input[type="search" i]::-webkit-search-cancel-button {
	-webkit-appearance: unset;
}

.container-fluid {
	max-width: 1920px;
}

@media screen and (min-width: 1200px) {

	.tpc-container,
	.custom-wrapper,
	.billing-wrapper {
		width: 100%;
		max-width: 1190px;
		padding: 0 15px;
		margin: 0 auto;
	}

	.billing-wrapper {
		max-width: 1200px;
	}

	.tpc-container {
		max-width: 1188px;
	}
}

.big-heading {
	font-size: 72px;
	line-height: 1.0833;
	font-weight: 800;
}

.sub-heading {
	font-size: 14px;
	line-height: 18px;
	font-weight: 600;
	letter-spacing: 2px;
}

.md-txt {
	font-size: 18px;
}

.sm-txt {
	font-size: 14px;
}

.profile {
	max-width: 48px;
}

.upload_title {
	color: #000000;
}

.do-latter {
	font-size: 12px;
	font-weight: 600;
	color: #9b33e9;
}

.para1 {
	line-height: 21px;
	color: #262626;
	font-family: 'Source Sans Pro', sans, serif;
}

.sp1 {
	font-weight: 600;
	color: #262626;
	font-family: 'Source Sans Pro', sans, serif;
}

.btn-1 {
	font: 700 16px/16px 'Montserrat', sans-serif;
	width: 100%;
	max-width: 250px;
	padding: 16px;
	line-height: 1;
	border-radius: 35px;
	-webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.8);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.8);
	background-image: -webkit-linear-gradient(119deg, #2c32b8 -5%, #7033d5 29%, #8133db 43%, #9933e6 56%);
	background-image: -moz-linear-gradient(119deg, #2c32b8 -5%, #7033d5 29%, #8133db 43%, #9933e6 56%);
	background-image: linear-gradient(119deg, #2c32b8 -5%, #7033d5 29%, #8133db 43%, #9933e6 56%);
	background-size: 200% auto;
}

.btn-2 {
	font: 700 16px/16px 'Montserrat', sans-serif;
	width: 100%;
	max-width: 160px;
	padding: 14px;
	background-color: #ffffff;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
	border: solid 1.8px #8133db;
	font-size: 16px;
	font-weight: 700;
	color: #8133db;
}

.btn-3 {
	font: 700 16px/16px 'Montserrat', sans-serif;
	color: #ffffff;
	width: 100%;
	max-width: 210px;
	padding: 14px;
	border: solid 1.8px #8133db;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(129, 51, 219, 0.5);
	background-color: #8133db;
}

.btn-2:hover {
	background-color: #8133db;
	color: #ffffff !important;
}

.btn-1:hover,
.btn-1:hover span {
	background-position: right center;
	color: #ffffff !important;
}

.btn:focus {
	box-shadow: none;
}

.btn:disabled {
	color: rgba(129, 51, 219, 0.35);
	box-shadow: 0 2px 4px 0 rgba(232, 201, 255, 0.5);
	border: solid 1.8px rgba(129, 51, 219, 0.35);
	background-color: rgba(129, 51, 219, 0.08);
	opacity: 1;
	pointer-events: none;
}

.btn-3:hover {
	background-color: #ffffff;
	color: #8133db !important;
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
	border: solid 1.8px #8133db;
}

.btn-1 i {
	font-size: 24px;
}

.tag {
	font-size: 10px;
	font-weight: 600;
	line-height: 1;
	color: #8133db;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 9px;
	border-radius: 2px;
	border: solid 1px #8133db;
	background-color: #f5effd;
	text-align: center;
	margin-right: 8px;
	margin-bottom: 8px;
}

.bg-dark {
	background-color: #171717 !important;
}

.flex-elem {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
}

.input-dark,
.bootstrap-tagsinput {
	font: 400 16px/1.5 'SourceSansPro', sans-serif;
	color: #fff !important;
	padding: 38px 16px 16px;
	border-radius: 4px !important;
	border: solid 1px #424344;
	background-color: #000000 !important;
	height: auto;
	box-shadow: none;
	resize: none;
}

.input-dark:hover,
.input-dark:focus {
	-webkit-box-shadow: 0 0 20px 0 #000000;
	box-shadow: 0 0 20px 0 #000000;
	border-color: #9e9e9e;
}

.input-dark~label {
	position: absolute;
	top: 16px;
	left: 16px;
	z-index: 4;
}

.file-type {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	cursor: pointer;
	opacity: 0;
}

.file-type::-webkit-file-upload-button {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.v-center {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}

body.modal-open {
	height: 100vh;
}

.modal-dialog {
	max-width: 644px;
	width: 100%;
}

.modal.fade .modal-dialog {
	-webkit-transform: scale(.5);
	transform: scale(.5);
}

.modal.show .modal-dialog {
	-webkit-transform: none;
	transform: none;
}

.modal.modal-static .modal-dialog {
	-webkit-transform: scale(1.02);
	transform: scale(1.02);
}

.modal-content {
	background-color: transparent;
	border: 0;
}

.modal-body {
	border-radius: 4px;
	border: solid 1px #c7cdd4;
	box-shadow: 0 4px 8px 0 rgba(83, 96, 107, 0.5);
}

body .modal-backdrop {
	opacity: 0.8 !important;
}

.modal-open section {
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.modal-open .modal {
	background-color: rgba(0, 0, 0, 0.3);
	overflow: hidden;
}

.show.modal {
	background-color: rgba(0, 0, 0, 0.9);
	overflow: hidden;
}

.modal button.close {
	outline: none !important;
}

/*App Header*/
#app-header,
#app-editor-header {
	background-color: #000;
	padding: 28px 0;
	opacity: 0.95;
	top: 0;
	left: 0;
	z-index: 11;
	-webkit-box-shadow: 0 2px 20px 0 #000000;
	box-shadow: 0 2px 20px 0 #000000;
}

#app-editor-header {
	padding: 17px 0;
}

#app-header .dropdown-menu .dropdown-item {
	background-color: transparent !important;
}

#app-editor-header~#proj-title-bar {
	margin-top: 72px;
}

#app-header .navbar-nav li a {
	padding: 0 20px;
	font-weight: 400;
}

#app-header .navbar-nav li.active a {
	color: #d75348 !important;
	font-weight: 800;
}

#app-header .dropdown-menu {
	-webkit-transform: translateX(-66%);
	-moz-transform: translateX(-66%);
	transform: translateX(-66%);
	margin-top: 20px;
	padding: 15px 20px;
	width: 230px;
	border-radius: 0;
}

#app-header .dropdown-menu::before {
	content: "";
	width: 0;
	height: 0;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid #fff;
	display: block;
	position: absolute;
	top: 0;
	left: 100%;
	transform: translate(-45px, -12px);
}

#app-header .dropdown-menu .dropdown-item {
	padding: 10px 0;
	font-size: 13px;
	font-weight: 500;
	color: #000;
	display: flex;
	align-items: center;
}

#app-header .dropdown-menu .dropdown-item .icon {
	flex: 1 1 auto;
	text-align: right;
}

/*End App Header*/

/*Dashboard Banner*/
#intro-section {
	padding: 260px 0 190px;
	background-image: -webkit-linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0) 50%), linear-gradient(to bottom, rgba(23, 23, 23, 0), #171717), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	background-image: -moz-linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0) 50%), linear-gradient(to bottom, rgba(23, 23, 23, 0), #171717), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0) 50%), linear-gradient(to bottom, rgba(23, 23, 23, 0), #171717), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
	overflow: hidden;
}

#intro-section #bg-video {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	bottom: 0;
	z-index: -1;
}

#bg-video>div {
	position: absolute;
	min-height: 100vh;
	min-width: 177.77vh;
	width: 100vw;
	height: 56.25vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#bg-video iframe {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
}

#intro-section .title-area h1 {
	-webkit-text-stroke: 1px #ffffff;
	background-color: rgba(255, 255, 255, 0);
	color: rgba(255, 255, 255, 0);
}

#intro-section .action-btn {
	font-weight: 500;
	width: 100%;
	max-width: 455px;
	padding: 31px;
	border: solid 1px #555555;
	background-color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
}

#intro-section .action-btn:hover span {
	color: #d75348 !important;
}

#intro-section .intro-btn a {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*End Dashboard Banner*/

/* Dashboard Projects*/
#project-section {
	padding: 48px 0 100px;
}

#project-section .view-all:hover,
#project-section .view-all:hover span {
	color: #fff !important;
}

#project-section .row-2 {
	margin: 0 -7px;
}

#project-section .status {
	width: 100%;
	max-width: 100%;
	min-height: 250px;
	margin-bottom: 14px !important;
	cursor: pointer;
	overflow: hidden;
}

#project-section .status.awaiting-customization {
	background-image: -webkit-linear-gradient(141deg, #2c32b8 -9%, #7033d5 35%, #8133db 53%, #9933e6 70%);
	background-image: linear-gradient(141deg, #2c32b8 -9%, #7033d5 35%, #8133db 53%, #9933e6 70%);
}

#project-section .status.transcript-failed,
#project-section .status.rendering-failed {
	background: rgb(215, 83, 72);
	background: -moz-linear-gradient(top, rgba(215, 83, 72, 1) 0%, rgba(162, 0, 15, 1) 50%);
	background: -webkit-linear-gradient(top, rgba(215, 83, 72, 1) 0%, rgba(162, 0, 15, 1) 50%);
	background: linear-gradient(to bottom, rgba(215, 83, 72, 1) 0%, rgba(162, 0, 15, 1) 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d75348', endColorstr='#a2000f', GradientType=0);
}

#project-section .status.rendering,
#project-section .status.transcribing {
	background-color: #d8d8d8;
	flex-wrap: wrap;
	text-align: center;
}

#project-section .status.rendering .status-img,
#project-section .status.transcribing .status-img {
	position: relative;
	top: 0;
	opacity: 1;
	mix-blend-mode: unset;
	transform: unset;
	align-self: flex-end;
	margin-bottom: 8px;
	width: 50px;
}

#project-section .status.rendering p,
#project-section .status.transcribing p {
	flex-basis: 100%;
	font-weight: 500;
	color: #747474 !important;
	align-self: flex-start;
}

#project-section .status.rendering:after,
#project-section .status.rendering .actions-icon,
#project-section .status.transcribing:after,
#project-section .status.transcribing .actions-icon {
	display: none;
}

#project-section .status .status-img {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
	mix-blend-mode: color-dodge;
	opacity: 0.2;
	width: 80px;
}

#project-section .status .proj_img {
	width: 100%;
	max-height: 250px;
	min-height: 250px;
	object-fit: cover;
}

#project-section .actions-icon {
	flex-wrap: nowrap;
	justify-content: center;
	position: absolute;
	top: 85%;
	left: 0;
	right: 0;
	opacity: 0;
	z-index: 2;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

#project-section .card-wrapper .status:hover .actions-icon,
#project-section .card-wrapper .img-wrapper:hover .actions-icon {
	top: 50%;
	opacity: 1;
}

#project-section .actions-icon .icon-wrapper {
	display: block;
	text-align: center;
	flex-basis: 25%;
	z-index: 10;
}

#project-section .actions-icon a {
	display: block;
	color: #ffffff;
	max-width: 48px;
	margin: 0 auto;
	padding: 13.6px 0;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.65);
}

#project-section .card-wrapper .status:after,
#project-section .card-wrapper .img-wrapper:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
	opacity: 0;
	z-index: 1;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

#project-section .card-wrapper .status:hover:after,
#project-section .card-wrapper .img-wrapper:hover:after {
	opacity: 1;
}

.dashboard-page .meta-wrapper {
	pointer-events: none;
}

/*End Dashboard Projects*/

/*Dashboard Lesson*/
#lesson-section {
	padding: 40px 0;
	background: url('./assets/images/bg-ad.png') center/cover no-repeat;
}

#lesson-section .btn-2:hover {
	background-color: #ffffff;
	color: #8133db !important;
	box-shadow: 0 8px 16px 0 rgba(116, 20, 187, 0.6);
}

/*End Dashboard Lesson*/

/*Dashboard Feature*/
#feature-section {
	padding: 108px 0 130px;
}

#feature-section .row {
	margin-bottom: 154px;
}

#feature-section .row-1 img {
	transform: scale(1.022);
	transform-origin: left;
	margin-left: 200px;
}

#feature-section .row-2 img {
	transform: scale(1.199);
	transform-origin: left;
}

#feature-section .row-2 {
	margin-bottom: 177px;
}

#feature-section .row-3 img {
	transform: scale(1.132);
	transform-origin: left;
	margin-left: 33px;
}

#feature-section .txt-block {
	padding: 14px 14px 14px 24px;
	border-left: 2px solid #d75348;
}

/*End Dashboard Feature*/

/*App Footer*/
#app-footer {
	padding: 18px 0;
	min-height: 70px;
	background-color: #262626;
}

#footer-nav li a {
	padding: 0 20px;
	font-weight: 500;
	font-size: 14px;
}

#app-footer .social-icons a {
	font-size: 25px;
}

#app-footer .copyright p {
	font-size: 14px;
	line-height: 1.5;
}

#app-footer .copyright p a {
	color: #1c75bb;
}

/*End App Footer*/

/*Watch Video Modal*/
#watch-video .modal-dialog {
	max-width: 1330px;
}

#watch-video .modal-content {
	max-width: 1130px;
}

.vid-wrapper {
	overflow: hidden;
}

.plyr__poster {
	background-size: cover;
}

.plyr__control.plyr__control--overlaid {
	background-color: transparent !important;
	opacity: 1;
}

.plyr__control--overlaid svg {
	height: 64px;
	width: 64px;
	fill: #9b33e9;
	left: 0;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.plyr__control--overlaid:hover svg {
	opacity: .8;
}

.plyr--video .plyr__control:hover {
	background-color: transparent;
}

.plyr--full-ui input[type="range"] {
	color: #fff;
}

.plyr__time {
	font: 500 14px/1 'Montserrat', sans-serif;
	color: #ffffff;
}

.plyr__volume {
	max-width: 40px;
	min-width: 0;
	margin-left: 12px !important;
}

.plyr video {
	object-fit: cover;
}

/*End Watch Video Modal*/

/*Progress Modal*/
#prog-modal .modal-body {
	padding: 40px;
}

#prog-modal .progress-label {
	font: 600 24px/normal 'Montserrat', sans-serif;
	color: #8133db;
}

#prog-modal .progress {
	border-radius: 12px;
	background-color: #e8e8e8;
}

#prog-modal .progress-bar {
	background-image: -webkit-linear-gradient(94deg, #2c32b8 -4%, #7033d5 26%, #8133db 38%, #9933e6 50%);
	background-image: linear-gradient(94deg, #2c32b8 -4%, #7033d5 26%, #8133db 38%, #9933e6 50%);
	transition: none;
}

/*End Progress Modal*/

/*Upload Video/Audio Modal*/
#upload-vid-aud .modal-body {
	padding: 40px 56px 32px;
}

#upload-vid-aud .proj-name {
	padding: 18px 16px;
	font: 500 14px/14px 'Montserrat', sans-serif;
	color: #000;
	caret-color: #9b33e9;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 #e8c9ff;
	border: solid 1.8px #d19df8;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

#upload-vid-aud .proj-name::placeholder {
	color: #e8c9ff;
}

#upload-vid-aud .proj-name:hover::placeholder {
	color: #8133db;
}

#upload-vid-aud p,
#upload-vid-aud p span {
	font-family: 'Source Sans Pro', sans-serif;
	color: #262626;
}

#upload-vid-aud .proj-name:hover {
	border: solid 1.8px #9b33e9;
}

#upload-vid-aud .proj-name:focus {
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.5);
	border: solid 1.8px #9b33e9;
}

.rebranding-logo {
	height: 50px;
	width: 280px;
	object-fit: unset;
}

#upload-vid-aud .save-project {
	padding: 14px;
}

/*End Upload Video/Audio Modal*/

/*Record Audio Modal*/
#rec-aud .modal-body {
	padding: 76px 50px 33px;
	background-image: linear-gradient(139deg, #2c32b8 -9%, #7033d5 34%, #8133db 52%, #9933e6 69%);
}

#rec-aud #rec-title {
	text-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
	font-weight: 600;
	line-height: normal;
}

#rec-aud #time-display {
	font-size: 96px;
	font-weight: 100;
	letter-spacing: 1.5px;
}

#rec-aud #record {
	color: #8133db;
	max-width: 72px;
	max-height: 72px;
	min-height: 72px;
	padding: 20.5px;
	border: 0;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
	align-items: center;
	justify-content: center;
}

#rec-aud #record:hover {
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.55);
}

#rec-aud #record:active,
#rec-aud #record:focus {
	outline: 0;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.55);
	border: solid 1.8px #d19df8;
	background-color: #faf2ff !important;
}

#rec-aud .modal-footer {
	padding: 24px;
}

#rec-aud .modal-footer .btn {
	min-width: 200px;
	line-height: 1.5;
	padding: 11px;
	margin: 0;
}

#rec-aud .modal-footer .btn-2 {
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
	border: solid 1.8px #8133db;
}

#rec-aud .modal-footer .btn:disabled {
	color: rgba(129, 51, 219, 0.35);
	opacity: 1;
	box-shadow: 0 2px 4px 0 rgba(232, 201, 255, 0.5);
	border: solid 1.8px rgba(129, 51, 219, 0.35);
	background-color: rgba(129, 51, 219, 0.08);
	pointer-events: none;
}

#rec-aud #wave {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#rec-aud #wave canvas {
	top: 50%;
	transform: translateY(-50%);
	opacity: .51;
	opacity: 0.3;
	mix-blend-mode: soft-light;
}

/*End Record Audio Modal*/

/*Confirm Modal*/
#upload-vid-aud .modal-body,
#confirm-del .modal-body {
	padding: 57px 30px;
	border-radius: 4px;
	border: solid 1px #c7cdd4;
	box-shadow: 0 4px 8px 0 rgba(83, 96, 107, 0.5);
}

#confirm-del .modal-body p {
	line-height: normal;
	color: #262626;
	max-width: 345px;
	font-family: 'SourceSansPro', sans-serif;
}

#confirm-del .modal-footer .btn {
	max-width: 200px;
	width: 100%;
	padding: 14px;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
	border: solid 2px #8133db;
	margin: 3.5px;
}

#confirm-del .modal-footer .btn-1 {
	box-shadow: 0 2px 4px 0 rgba(129, 51, 219, 0.5);
	background-color: #8133db;
}

/*End Confirm Modal*/

/*Publish Modal*/
#publish .modal-body {
	padding: 40px 24px;
}

#publish .modal-body .icons-wrapper .icon-wrapper {
	flex-basis: 15%;
}

#publish .modal-body .icons-wrapper .img-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 88px;
	min-height: 88px;
	padding: 14px 0;
	overflow: hidden;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
	border: solid 2px transparent;
	border-radius: 50%;
	outline: none;
}

#publish .modal-body .icons-wrapper .img-wrapper:hover {
	box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.24);
}

#publish .modal-body .icons-wrapper .img-wrapper.active,
#publish .modal-body .icons-wrapper .img-wrapper:active,
#publish .modal-body .icons-wrapper .img-wrapper:focus {
	box-shadow: 0 0 16px 0 rgba(129, 51, 219, 0.4);
	border: solid 2px #8133db;
}

#publish .modal-body .icons-wrapper .img-wrapper img {
	width: 100%;
	max-width: 56px;
}

#publish .modal-footer {
	display: block;
}

#publish #vid-details {
	display: none;
}

#publish .modal-footer p {
	font-weight: 700;
	line-height: 2.29;
	color: #000000;
}

#publish .modal-footer p::after,
#publish .modal-footer p::before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 230px;
	height: 1px;
	background-color: #cccccc;
}

#publish .modal-footer p::after {
	left: unset;
	right: 0;
}

#pub-form {
	max-width: 420px;
}

#publish .modal-footer input,
#publish .modal-footer textarea,
#publish .modal-footer #sel-acc {
	font: 400 14px/14px 'SourceSansPro', sans-serif;
	color: #d19df8;
	max-width: 420px;
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(129, 51, 219, 0.55);
	border: solid 1px #8133db;
	padding: 15px 16px;
	outline: none;
}

#publish .modal-body #copy-link {
	font-weight: 700;
	max-width: 160px;
	padding: 14px;
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
	border: solid 2px #8133db;
}

#publish .modal-footer textarea,
#publish .modal-footer#vid-details input {
	padding: 27px 16px 10px;
	color: #000;
}

#publish .modal-footer textarea:hover,
#publish .modal-footer#vid-details input:hover,
#publish .modal-footer #sel-acc:hover,
#publish .modal-footer textarea:focus,
#publish .modal-footer#vid-details input:focus,
#publish .modal-footer #sel-acc:focus {
	border: solid 1px #9b33e9;
	box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.5);
}

#publish .modal-footer textarea {
	height: 112px;
}

#publish #vid-details input~.label,
#publish .modal-footer textarea~.label,
#publish .modal-footer #sel-acc~.label {
	color: #d19df8;
	font: 600 14px/14px 'SourceSansPro', sans-serif;
	position: absolute;
	top: 50%;
	left: 16px;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}

#publish .modal-footer textarea~.label {
	top: 19px;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	transform: translateY(0);
}

#publish .modal-footer textarea:focus~.label,
#publish .modal-footer textarea.has-value~.label {
	top: 10px;
}

#publish #vid-details input:focus~.label,
#publish .modal-footer #sel-acc~.label.up,
#publish .modal-footer #sel-acc.has-value~.label,
#publish #vid-details input.has-value~.label {
	top: 10px;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	transform: translateY(0);
}

#publish .modal-footer #sel-acc {
	font-weight: 600;
	color: #000000 !important;
	text-align: left;
	padding: 28px 16px 26px;
	z-index: 9;
	background-color: transparent;
}

#publish .modal-footer #sel-acc~.icon {
	position: absolute;
	top: 50%;
	right: 16px;
	color: #8133db !important;
	-webkit-transform: translateY(-50%) rotateX(0);
	-moz-transform: translateY(-50%) rotateX(0);
	transform: translateY(-50%) rotateX(0);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

#publish .modal-footer #sel-acc~.icon.up {
	-webkit-transform: translateY(-50%) rotateX(180deg);
	-moz-transform: translateY(-50%) rotateX(180deg);
	transform: translateY(-50%) rotateX(180deg);
}

#publish .modal-footer #sel-acc::after {
	content: unset;
}

#publish .modal-footer #sel-acc~.dropdown-menu {
	box-shadow: 0 1px 2px 0 #e8c9ff;
	border: solid 1px #d19df8;
	background-color: rgba(255, 255, 255, 0.85);
	padding: 4px 16px 0;
}

#publish .modal-footer #sel-acc~.dropdown-menu li {
	align-items: center;
	padding: 12px 0;
	border-bottom: solid 1px #e8c9ff;
}

#publish .modal-footer #sel-acc~.dropdown-menu li:last-child {
	border: 0;
}

#publish .modal-footer #sel-acc~.dropdown-menu li::after {
	content: '';
	display: block;
	background: url(./assets/images/right-arrow-2.svg) center/16px no-repeat;
	width: 16px;
	height: 16px;
	opacity: .4;
}

#publish .modal-footer #sel-acc~.dropdown-menu li:hover::after {
	opacity: 1;
}

#publish .modal-footer #sel-acc~.dropdown-menu li img {
	width: 6.22%;
	margin-right: 8px;
}

#publish .modal-footer #sel-acc~.dropdown-menu li a {
	font: 600 14px/normal 'SourceSansPro', sans-serif;
	color: #000000;
	margin-left: 8px;
	outline: none;
}

#publish .modal-footer a:hover,
#publish .modal-footer #sel-acc~.dropdown-menu li:hover a {
	color: #8133db !important;
}

/*End Publish Modal*/

/*Toaster*/
.toast-container.toast-top-center {
	position: fixed;
	top: 104px;
	z-index: 9;
	background-color: transparent;
	width: 100%;
	max-width: 100%;
	box-shadow: none;
}


.toast-container.toast-top-center>.toast-body {
	width: 100%;
	max-width: 1160px;
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(83, 96, 107, 0.5);
	background-image: none !important;
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.toast-container.toast-top-center>.toast-success {
	border: solid 1px #01803f;
	background-color: #ebf5f0;
}

.toast-container.toast-top-center>.toast-error {
	border: solid 1px #a2000f;
	background-color: #f8ebec;
}

.toast-container>.toast-body .toast-message:before {
	content: "";
	font-family: FontAwesome;
	font-size: 24px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(-36px, -50%);
}

.toast-container>.toast-success .toast-message::before {
	content: "\f058";
}

.toast-container>.toast-error .toast-message::before {
	content: "\f06a";
}

.toast-container .toast-message {
	font: 700 16px/16px 'Montserrat', sans-serif;
}

.toast-container>.toast-success .toast-message {
	color: #01803f;
}

.toast-container>.toast-error .toast-message {
	color: #a2000f;
}

/*My Account Styles*/
#acc-sec-1 {
	padding: 176px 0 90px;
}

#acc-sec-1 .card {
	background-color: #000;
	border: 1px solid #6801a2;
}

#acc-sec-1 .card label {
	font: 600 14px/normal 'SourceSansPro', sans-serif;
	color: #747474;
}

#acc-sec-1 .card .input-dark {
	padding-top: 30px;
}

#acc-sec-1 .card .btn {
	max-width: 100%;
	font-size: 14px;
}

#acc-sec-1 .card .btn.update-pass {
	max-width: max-content;
	font-size: 16px;
}

#acc-sec-1 .card .input-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	max-width: 265px;
	max-height: 265px;
	top: 50%;
	left: 50%;
	display: flex;
	align-items: center;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	background-color: rgba(0, 0, 0, 0.7);
	opacity: 0;
}

#acc-sec-1 .card-body:hover .input-wrapper {
	opacity: 1;
}

#acc-sec-1 .card .input-wrapper .edit-prof {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 2;
}

#acc-sec-1 .card .input-wrapper .edit-prof~svg {
	opacity: 0;
	align-self: flex-end;
	-webkit-transition: opacity .8s ease-in-out;
	transition: opacity .8s ease-in-out;
}

#acc-sec-1 .card-body:hover .input-wrapper .edit-prof~svg {
	opacity: 1;
}

/*End My Account Styles*/

#footer-nav li a:hover {
	color: blue !important;
}

#app-footer .copyright p a {
	color: #d75348 !important;
}

#app-footer .copyright p {
	font-size: 16px;
}

#footer-nav li a {
	font-size: 16px;
	font-weight: 400 !important;
}

.login-page .form-control:focus {
	background: none !important;
	border: none !important;
	box-shadow: none !important;
	color: #FFF !important;
}

.login-page .login-form input:focus~label {
	color: #FFF !important;
}

#int-sec-1 .acc-wrapper:hover {
	background: linear-gradient(180deg, #9b34e5, #2d32b9);
	box-shadow: 0 0 20px 0 #9b34e5, 0 0 20px 0 #2d32b9;
	transform: scale(1.1) !important;
	transform: scale(1.1);
	color: #FFF !important;
}

#int-sec-1 .acc-wrapper:hover p {
	color: #FFF !important;
}

.sec-block {
	padding: 40px;
	background: #000;
	border-radius: 32px;
	box-shadow: 0 0 30px rgb(0 0 0 / 37%);
	margin-top: 30px;
}

.sec-block-wrap {
	margin-bottom: 40px;
}

.form-groupN {
	background: #1a1a1a;
	border: 1px solid #404040;
	border-radius: 8px;
	padding: 10px 20px;
}

.form-groupN p {
	color: rgb(200, 216, 233);
	margin: 0;
	padding: 0;
	font-size: 13px;
}

.form-groupN .inp {
	width: 100%;
	background: #1a1a1a;
	border: 0;
	outline: none;
	color: #FFF;
}

.colorBtn {
	width: 100%;
	background: #3686ff;
	background: linear-gradient(135deg, #3686ff, #c867ff 50%, #c867ff 0, #3686ff);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3686ff", endColorstr="#3686ff", GradientType=1);
	background-size: 200% auto;
	padding: 15px 0;
	border-radius: 100px;
	border: none;
	color: #FFF;
	font-size: 18px;
}

.colorBtn:hover {
	background-position: 100%;
}

.account-data-btn {
	padding: 8px 15px;
	padding-bottom: 10px;
	border-radius: 100px;
	border: none;
	text-transform: uppercase;
	font-size: 14px !important;
	font-weight: 500 !important;
	background: #2ea2ce;
	color: #FFF;
}

.process-data {
	background: #df8b4b;
}

.delete-data {
	background: #d83c47;
}

.data-block {
	padding: 25px;
	background: #1a1a1a;
	border-radius: 32px;
	margin-top: 40px;
}

.data-block:first-child {
	margin-top: 0;
}

.data-block p {
	font-size: 15px;
}

.data-block p a,
.data-block p span {
	font-size: 15px;
	font-weight: 500;
	color: #0d6efd;
}

.data-block p span {
	color: rgb(255, 99, 99);
}

.sec-upgrades {
	padding-left: 50px !important;
	padding-right: 50px !important;
}

.sidebar .topics {
	background: #28335a;
	border-radius: 20px;
	overflow: hidden;
}

.sidebar .topics a {
	padding: 20px 37px !important;
	cursor: pointer;
	color: #fff;
	background: none;
	border-radius: 0;
	border: none;
	padding: unset;
	display: block;
	font-family: "Poppins", sans-serif;
}

.sidebar .topics a:hover,
.sidebar .topics a.active {
	background: #006180;
	color: #FFF !important;
}

.sec-upgrades .tab-header {
	background: #22459f;
	padding: 20px 40px;
}

.sec-upgrades .tab-header h2 {
	color: #FFF;
	font-size: 20px;
	font-weight: 400;
}

.sec-upgrades .tab-content {
	padding-left: 50px;
}

.sec-upgrades .tab-desc {
	background: #28335a;
	padding: 20px 40px 30px;
}

.sec-upgrades .tab-pane {
	border-radius: 20px;
	overflow: hidden;
}

.tab-desc #tpc-section {
	background: none;
}

#sup-banner {
	padding: 300px 0 220px 0 !important;
}

#sup-banner .title-area h3 {
	font-size: 50px !important;
	font-weight: 700 !important;
	padding-bottom: 30px;
}

#tpc-section .card-row {
	justify-content: center;
}

.container-fluid-alt {
	max-width: 1500px;
}

.sec-block.for-table {
	padding: 0;
	padding-bottom: 20px;
}

.table-wrapper {
	border-radius: 32px;
	overflow: hidden;
}

.table-wrapper tr th {
	background: #1b344e;
	border: none !important;
	color: #fff;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.table-wrapper tr th,
.table-wrapper tr td {
	padding: 24px 67px;
}

.table-wrapper tr td {
	border: none;
	color: #b4b4b4;
	font-family: "Poppins", sans-serif;
}

#acc-sec-1 {
	min-height: calc(100vh - 70px);
}



/*>>> Responsive <<<*/

/* Screen-size 1500 */
@media screen and (max-width: 1500px) {
	#project-section .card-wrapper {
		max-width: 320px;
		margin: 0 auto;
	}

	#project-section .status,
	#project-section .status .proj_img {
		min-height: 180px;
		max-height: 180px;
		margin: 0 auto;
	}

	#feature-section .row-1 img {
		margin-left: 0px;
	}

	#feature-section .row-3 img {
		transform-origin: right;
		margin-left: 0;
	}

	#app-footer .copyright p {
		font-size: 12px;
	}

	#footer-nav li a {
		padding: 0px 7px;
		font-size: 12px;
	}
}

/* Screen-size 1199 */
@media screen and (max-width: 1199px) {
	br {
		display: none;
	}

	h1 {
		font-size: 35px;
		line-height: 48px;
	}

	h2 {
		font-size: 26px;
		line-height: 36px;
	}

	h3 {
		font-size: 30px;
		line-height: 40px;
	}

	h4 {
		font-size: 22px;
		line-height: 32px;
	}

	h5 {
		font-size: 20px;
		line-height: 30px;
	}

	h6 {
		font-size: 18px;
		line-height: 28px;
	}

	.big-heading {
		font-size: 64px;
	}

	#watch-video .modal-content {
		max-width: 95%;
	}

	#project-section .status .proj_img,
	#project-section .status {
		max-width: 100%;
		max-height: 300px;
		min-height: 300px;
	}

	#app-footer #footer-nav {
		justify-content: center;
	}

	#footer-nav li a {
		padding: 0px 10px;
		font-size: 14px;
	}

	#app-footer .copyright p {
		font-size: 14px;
	}

	#acc-sec-1 {
		padding: 130px 0 60px;
	}

	#acc-sec-1 .col-md-4 .card .card-body {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media screen and (max-width: 1023px) {
	#tpc-section .card {
		max-width: 100% !important;
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		padding-left: 0 !important;
	}

	#tpc-section .card {
		max-width: 100% !important;
		width: 100% !important;
	}

	.tpc-section-wrap.d-flex {
		display: block !important;
		padding: 0 15px !important;
	}

	.data-block .col-lg-4.text-right {
		text-align: left !important;
		padding-top: 30px !important;
	}

	.sec-upgrades {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}

	.sec-upgrades .tab-content {
		padding-left: 0;
		margin-top: 40px;
	}

	.table tr td,
	.table tr th {
		white-space: nowrap;
	}
}

/* Screen-size 991 */
@media screen and (max-width: 991px) {
	.big-heading {
		font-size: 60px;
	}

	#app-header .navbar-toggler {
		border-color: #d75348;
	}

	#app-nav {
		padding-top: 30px;
	}

	#app-nav .navbar-nav {
		width: 100%;
		border-top: 2px solid rgba(142, 49, 218, 0.4);
		padding-top: 20px;
		max-width: 800px;
		margin: 0 auto;
	}

	#app-nav .navbar-nav li {
		padding: 12px 0;
	}

	#app-header .dropdown-menu {
		-webkit-transform: translateX(15px);
		-moz-transform: translateX(15px);
		transform: translateX(15px);
		max-width: 320px;
	}

	#app-header .dropdown-menu::before {
		left: 60px;
	}

	#intro-section {
		padding: 190px 0 100px;
	}

	#intro-section .colmn-1 {
		margin: 0 auto;
	}

	#project-section {
		padding-bottom: 60px;
	}

	#project-section .row .col {
		margin: 0 auto;
	}

	#feature-section {
		padding: 80px 0;
	}

	#feature-section .row,
	#feature-section .row-2 {
		margin-bottom: 100px;
	}

	#feature-section .col {
		margin: 0 auto;
	}

	#feature-section .row img {
		transform: unset;
	}

	#app-footer {
		padding: 20px 0;
	}

	#app-footer #footer-nav,
	#app-footer .inner-row {
		justify-content: center;
	}

	#app-footer .inner-row .social-icons {
		flex-basis: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
}

/* Screen-size 767 */
@media screen and (max-width: 767px) {
	h4 {
		font-size: 20px;
		line-height: 30px;
	}

	.modal-dialog .close img {
		width: 12px;
	}

	#sup-banner {
		padding: 150px 0 50px 0 !important;
	}

	#sup-banner .title-area h3 {
		font-size: 23px !important;
		padding-bottom: 0;
	}

	#tpc-section .card {
		max-width: 100% !important;
		width: 100% !important;
	}

	#tpc-section .card {
		max-width: 100% !important;
		width: 100% !important;
	}

	.tpc-section-wrap.d-flex {
		display: block !important;
		padding: 0 15px !important;
	}

	.sec-block {
		padding: 20px 15px;
	}

	.sec-upgrades {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

/* Screen-size 640 */
@media screen and (max-width: 640px) {
	h3 {
		font-size: 25px;
		line-height: 35px;
	}

	h6,
	.sub-txt {
		font-size: 18px;
		line-height: 1.6;
	}

	.md-txt {
		font-size: 16px;
	}

	.big-heading {
		font-size: 55px;
	}

	#intro-section {
		padding: 150px 0 80px;
	}
}

/* Screen-size 575 */
@media screen and (max-width: 575px) {
	h3 {
		font-size: 22px;
		line-height: 32px;
	}

	h4 {
		font-size: 18px;
		line-height: 28px;
	}

	p,
	a,
	li,
	span {
		font-size: 14px;
		line-height: 1;
	}

	.container-fluid {
		padding: 0 15px !important;
	}

	.big-heading {
		font-size: 50px;
	}

	.input-dark,
	.bootstrap-tagsinput {
		font-size: 14px;
	}

	#project-section .status .proj_img,
	#project-section .status {
		max-height: 250px;
		min-height: 250px;
	}

	#confirm-del .modal-body {
		padding: 30px 20px;
	}

	#upload-vid-aud .modal-body {
		padding: 30px 40px;
	}

	#footer-nav li a {
		padding: 0px 10px;
	}

}

/* Screen-size 440 */
@media screen and (max-width: 440px) {
	.big-heading {
		font-size: 35px;
	}

	#footer-nav li a,
	#app-footer .copyright p {
		font-size: 12px;
	}

	p,
	a,
	li,
	span {
		font-size: 14px;
	}

	h3 {
		font-size: 20px;
		line-height: 30px;
	}

	#project-section .status .proj_img,
	#project-section .status {
		max-width: 100%;
		max-height: 180px;
		min-height: 180px;
	}
}


/* ankit ***************************************** */


.upload-btn-wrapper .custom-file-input {
	position: absolute;
	top: 0;
}

.custom-file-input {
	position: absolute;
	top: 0;
}


.login-form .form-group .form-control {
	color: #fff !important;
}

.demoLink {
	display: inline-block;
	color: #FFF !important;
	font-weight: 600;
	padding: 14px 30px;
	border-radius: 4px;
	margin-top: 30px;
	cursor: pointer;
	border: none;
	transition: all 0.5s ease;
	background: #8133db;
}

.demoLink:focus {
	border: none;
	box-shadow: none;
	outline: 0;
}

.demoLink .fa {
	margin-right: 10px;
}

.demoLink:hover {
	color: #FFF;
	opacity: 0.5;
}

.Upgrade-CourseReelAl .btn-close{
	background: none;
	border: none;
}
.Upgrade-CourseReelAl button:focus {
    outline: none!important;
}

.Upgrade-CourseReelAl .modal-content {
	top: 250px;

}
.CourseReelAl-bg {
	width: 100%;
	background-size: 300% 300%;
	background-image: linear-gradient(
		  -45deg, 
		  rgba(93,0,255,1) 0%, 
		  rgba(94,0,255,1) 25%, 
		  rgba(152,68,183,1) 51%, 
		  rgba(255,53,127,1) 100%
	) !important;  
	animation: AnimateBG 5s ease infinite;
  }
  
  @keyframes AnimateBG { 
	0%{background-position:0% 50%}
	50%{background-position:100% 50%}
	100%{background-position:0% 50%}
  }

.Upgrade-CourseReelAl .modal-header {
    justify-content: end!important;
	border-bottom:none!important;
}

.Upgrade-CourseReelAl .modal-body {
	border: none!important;
	box-shadow: none;
	border-radius: 0 !important;
}
.Upgrade-CourseReelAl .modal-body h1{
	font-size: 30px;
	color:#fff;
}
.Upgrade-CourseReelAl .modal-body p{
	color:#fff;
	font-size: 18px;
}
.btn-success:not(:disabled):not(.disabled):active{
	color: #9706b1;
    background-color: #f8f8f8;
    border-color: #fff;
	font-weight: bold;
}
.btn-success:not(:disabled):not(.disabled):active:focus{
	box-shadow:none;
}

.Upgrade-CourseReelAl .modal-footer {
	border-top: none;
	justify-content: center!important;
}
.Upgrade-CourseReelAl .btn-success {
    color: #9706b1;
    background-color: #f8f8f8;
    border-color: #fff;
    font-weight: bold;
    padding: 9px 20px;
}