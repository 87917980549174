
/*preloader*/
.loader-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.preloader {
    width: 80px;
    height: 80px;
    border: 1px #8933df solid;
    border-radius: 50%;
    -webkit-animation: rotation 1s ease-in-out infinite;
    -moz-animation: rotation 1s ease-in-out infinite;
    animation: rotation 1s ease-in-out infinite;
    margin: 30px auto;
    text-align: center;
}

.preloader:after {
    width: 10px;
    height: 10px;
    background-color: rgb(129, 51, 219);
    border-radius: 100%;
    position: absolute;
    content: "";
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotation {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}