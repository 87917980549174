/* billing styles */
#bill-sec-1 {
    padding: 176px 0 90px;
}

#bill-sec-1 .pricing-table {
    background-color: #fafafa;
    opacity: 0.98;
    -webkit-box-shadow: 0 10px 30px 0 #000000, 10px 0 30px 0 #000000, -10px 0 30px 0 #000000, 0 -10px 30px 0 #000000;
    box-shadow: 0 10px 30px 0 #000000, 10px 0 30px 0 #000000, -10px 0 30px 0 #000000, 0 -10px 30px 0 #000000;
}

#bill-sec-1 .pricing-table .card {
    background-color: transparent;
    padding: 10px 0 16px;
    border-radius: 0;
    border: 0;
    cursor: pointer;
}

#bill-sec-1 .pricing-table .card.active {
    cursor: default;
}

#bill-sec-1 .pricing-table .card::before,
#bill-sec-1 .pricing-table .card::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: transparent;
    z-index: -1;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

#bill-sec-1 .pricing-table .card::before {
    width: 0;
    left: 50%;
    height: 12px;
    z-index: 1;
    background-image: -webkit-linear-gradient(95deg, #2c32b8 -4%, #7033d5 26%, #8133db 38%, #9933e6 50%);
    background-image: linear-gradient(95deg, #2c32b8 -4%, #7033d5 26%, #8133db 38%, #9933e6 50%);
}

#bill-sec-1 .pricing-table .card::after {
    background-color: #fff;
    min-height: 488px;
    opacity: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
}

#bill-sec-1 .pricing-table .card:hover::before,
#bill-sec-1 .pricing-table .card.active::before {
    left: 0;
    width: 100%;
}

#bill-sec-1 .pricing-table .card.active::after {
    opacity: .92;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
}

#bill-sec-1 .pricing-table .card .card-header {
    padding: 32px 0 30px;
    border-top: 2px solid #e8c9ff;
    border-bottom: 2px solid rgba(232, 201, 255, 0.3);
    border-radius: 0;
    background-color: transparent;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

#bill-sec-1 .pricing-table .card:hover .card-header,
#bill-sec-1 .pricing-table .card.active .card-header {
    border-bottom-color: rgba(155, 51, 233, 0.3);
}

#bill-sec-1 .pricing-table .small-head {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 1px;
}

#bill-sec-1 .pricing-table .card:hover .small-head:first-child,
#bill-sec-1 .pricing-table .active .small-head:first-child {
    color: #424344;
}

#bill-sec-1 .pricing-table .card-header h3~.small-head {
    font-weight: 500;
    letter-spacing: normal;
}

#bill-sec-1 .pricing-table .card-header h3 {
    justify-content: center;
    align-items: center;
    font-size: 56px;
    line-height: normal;
    letter-spacing: 2.8px;
    color: #000;
}

#bill-sec-1 .pricing-table .card-header h3 .currency,
#bill-sec-1 .pricing-table .card-header h3 .period {
    font-size: 32px;
    color: #000;
    align-self: flex-start;
    margin-top: 4px;
}

#bill-sec-1 .pricing-table .card-header h3 .currency {
    font-weight: 600;

}

#bill-sec-1 .pricing-table .card-header h3 .period {
    font-weight: 700;
    letter-spacing: 1px;
}

#bill-sec-1 .pricing-table .card-block {
    padding: 8px 0 0;
    max-width: 210px;
}

#bill-sec-1 .pricing-table .card-block ul {
    margin-bottom: 4.43px;
}

#bill-sec-1 .pricing-table .card-block ul li {
    background-color: transparent;
    border: 0;
    padding: 12px 0 12px 28px;
    font-family: 'SourceSansPro', sans-serif;
    line-height: normal;
    color: #000000;
}

#bill-sec-1 .pricing-table .card-block ul .not-ft {
    color: #9e9e9e;
}

#bill-sec-1 .pricing-table .card-block ul li::before {
    content: "";
    background: url(../../assets/images/checkmark.svg) center center/cover no-repeat;
    display: block;
    width: 17px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

#bill-sec-1 .pricing-table .card-block ul li.not-ft::before {
    background-image: url(../../assets/images/missing.svg);
}

#bill-sec-1 .pricing-table .pricing-btn {
    max-width: 210px;
    padding: 15px;
    border-width: 1px;
}

/* end billing styles */

/* << RESPONSIVE >> */

/* Screen-size 991 */
@media screen and (max-width: 991px) {
    #bill-sec-1 .pricing-table .card::after {
        min-height: auto;
    }
}

/* Screen-size 640 */
@media screen and (max-width: 640px) {
    #bill-sec-1 {
        padding: 150px 0 60px;
    }
}